import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Button, DatePicker, Icon, theme } from "@golfersweden/pegora-ui";

import generateConfig from "helpers/generateConfig";
import { useQuery } from "@apollo/react-hooks";
import { GET_STARTTIME_SCHEDULE } from "graphql/Queries";

import ExternalClient from "graphql/ExternalClient";
import TimeBlock from "../elements/TimeBlock";
import NavBar from "../elements/NavBar";
import LoadingContainer from "../elements/LoadingContainer";
import Empty from "../elements/Empty";

const ResultContainer = styled.div`
  transition: height 3.5s ease-in;
  margin: 0px 0px;
  display: block;
`;

const Container = styled.div`
  padding: 10px;
`;

const CustomDatePicker = styled(DatePicker)`
  font-size: 15px;
  text-align: center;
  flex-basis: 100%;
  align-self: stretch;
`;

const CalendarDayView = ({
  configuration,
  setDate,
  date,
  profile,
  occupancy
}) => {
  const conf = generateConfig({
    resolution: "day",
    config: configuration,
    date: date,
    occupancy,
    profile
  });

  const { loading, data } = useQuery(GET_STARTTIME_SCHEDULE, {
    variables: { input: conf },
    client: ExternalClient,
    clientState: { defaults: {}, resolvers: {} },
    context: {
      headers: {
        "Accept-Language": configuration && configuration.getLanguage.code
      }
    }
  });

  const onAddDate = () => {
    const newDate = moment(date)
      .add(1, "day")
      .set("hour", 0)
      .set("minute", 0)
      .utc();
    console.log(newDate);
    setDate(newDate);
  };

  const onSubDate = () => {
    const newDate = moment(date)
      .subtract(1, "day")
      .set("hour", 0)
      .set("minute", 0)
      .utc();
    setDate(newDate);
  };

  const onSetDate = (moment, datestring) => {
    const newDate = moment
      .set("hour", 0)
      .set("minute", 0)
      .utc();
    setDate(newDate);
  };

  let isDollar = false;

  // Hack because of missing timezone fetches times over different dates
  const parsedItems =
    data && data.startTimeSchedule
      ? data.startTimeSchedule.bookableItems.map(item => {
          return {
            ...item,
            startTime: moment(item.startTime)
              .set("date", moment(date).day())
              .utc(),
            endTime: moment(item.endTime)
              .set("date", moment(date).day())
              .utc()
          };
        })
      : [];

  if (parsedItems && parsedItems.length) {
    isDollar = parsedItems[0].localizedPrice.includes("$");
  }

  return (
    <Container>
      <NavBar>
        <Button
          style={{ color: theme.clear_blue }}
          type="link"
          icon="left"
          onClick={onSubDate}
        />
        <CustomDatePicker
          onChange={onSetDate}
          value={moment(date)}
          format="dddd D MMMM YYYY"
          allowClear={false}
          suffixIcon={
            <Icon type="down" style={{ color: "white", fontWeight: "bold" }} />
          }
        />
        <Button
          style={{ color: theme.clear_blue }}
          type="link"
          icon="right"
          onClick={onAddDate}
        />
      </NavBar>
      <ResultContainer>
        <LoadingContainer loading={loading}>
          {parsedItems.length ? (
            parsedItems.map((bookableItem, i) => (
              <TimeBlock
                item={bookableItem}
                isDollar={isDollar}
                key={i}
                profile={profile}
              />
            ))
          ) : (
            <div>{!loading && <Empty>Inga tider för valt datum</Empty>}</div>
          )}
        </LoadingContainer>
      </ResultContainer>
    </Container>
  );
};

export default CalendarDayView;
