import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import {
  Tabs,
  Select,
  Button,
  Icon,
  List,
  theme
} from "@golfersweden/pegora-ui";
import "./Calendar.css";
import CalendarDayView from "./views/CalendarDayView";
import CalendarMonthView from "./views/CalendarMonthView";
// import CalendarWeekView from "./views/CalendarWeekView";
import CalendarYearView from "./views/CalendarYearView";
import EditorBaseSettingsForm from "components/ScheduleEditor/forms/EditorBaseSettingsForm";
import PriceConfigForm from "components/ScheduleEditor/forms/PriceConfigForm";
import LanguageForm from "components/ScheduleEditor/forms/LanguageForm";
import RulesForm from "components/RulesForm";
import moment from "moment";
import { GET_RULES } from "graphql/Queries";
import {
  //   CREATE_SEASON,
  UPDATE_SEASON,
  UPDATE_RULE,
  CREATE_RULE
} from "graphql/Mutations";
import { useMutation } from "@apollo/react-hooks";
import SeasonView from "components/SeasonView";
import profiles from "data/profiles";
import CustomLabel from "components/ScheduleEditor/CustomLabel";

const { Option } = Select;
const { TabPane } = Tabs;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CalendarContainer = styled.div`
  margin-top: 20px;
  position: relative;
`;

const LoadingOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
`;

const SelectProfileContainer = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-basis: 100%;
  align-items: flex-start;
`;

const CustomList = styled(List)`
  width: 300px;
  /*
  overflow: hidden;
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  */

  color: white;
  font-family: Space Grotesk;
  font-size: 16px;
  margin-right: 20px;
  overflow: hidden;
  border: none;
`;

const CustomListItem = styled(List.Item)`
  transition: background-color ease-in-out 0.2s;
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
  &.selected {
  }

  ${props =>
    props.selected &&
    css`
      background-color: ${theme.pegora_coral}!important;
      color: white;
    `}
`;

const RulesTab = ({ items }) => {
  const [openItem, setOpenItem] = useState(null);
  const [addItem, setAddItem] = useState(false);

  const [updateRule] = useMutation(UPDATE_RULE, {
    refetchQueries: [{ query: GET_RULES }]
  });
  const [createRule] = useMutation(CREATE_RULE, {
    refetchQueries: [{ query: GET_RULES }]
  });

  return (
    <TabContent>
      <div style={{ textAlign: "left" }}>
        <CustomLabel style={{ margin: 0 }}>Regler</CustomLabel>
        <Button
          type="link"
          style={{
            borderBottom: "2px solid #ff6575",
            borderRadius: 0,
            textAlign: 'left',
            padding: 0,
            fontSize: 18,
            fontFamily: "Space Grotesk"
          }}
          onClick={() => {
            setOpenItem(null);
            setAddItem(!addItem);
          }}
        >
          Ny regel +
        </Button>
      </div>
      <Row>
        <div>
          {/* <AutoComplete dataSource={items.map(rule => rule.name)} />*/}
          <CustomList
            size="small"
            /*header={<div>Regler</div>} */
            bordered={false}
            split={false}
            dataSource={[
              addItem && { name: "Ny regel", id: null },
              ...items
            ].filter(i => i)}
            renderItem={item => (
              <CustomListItem
                onClick={() => {
                  if (item.id) {
                    setAddItem(false);
                    setOpenItem(item);
                  }
                }}
                selected={openItem === item || (!openItem && !item.id)}
              >
                {item.name}
              </CustomListItem>
            )}
          />
        </div>
        <div style={{ flexGrow: 1 }}>
          <CalendarContainer
            style={{
              margin: "0",
              // borderLeft: 'none',
              overflowY: "auto",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0
            }}
          >
            {openItem && (
              <RulesForm
                key={openItem.id}
                item={openItem}
                onSubmit={ruleData => {
                  updateRule({
                    variables: {
                      input: {
                        ...ruleData,
                        expressions: JSON.stringify(ruleData.expressions),
                        id: openItem.id
                      }
                    }
                  });
                  setAddItem(false);
                }}
              />
            )}
            {addItem && (
              <RulesForm
                item={{
                  expressions: [{ subject: "all" }]
                }}
                // onChange={val => () => setRules([...rules, val])}
                onSubmit={val => {
                  createRule({
                    variables: {
                      input: {
                        ...val,
                        expressions: JSON.stringify(val.expressions)
                      }
                    }
                  });
                  setAddItem(false);
                }}
              />
            )}
          </CalendarContainer>
        </div>
      </Row>
    </TabContent>
  );
};

const SeasonsTab = ({ items, rules }) => {
  const [openItem, setOpenItem] = useState(null);
  const [addItem, setAddItem] = useState(false);

  const [updateSeason] = useMutation(UPDATE_SEASON, {});

  useEffect(() => {
    if (openItem) {
      items.forEach(item => {
        if (item.id === openItem.id) {
          setOpenItem(item);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, rules]);

  return (
    <TabContent>
      {/* <Seasons configuration={configuration} showRules /> */}
      <div style={{ textAlign: "left" }}>
        <CustomLabel style={{ margin: 0 }}>Perioder</CustomLabel>
        <Button
          type="link"
          style={{
            textAlign: 'left',
            borderBottom: "2px solid #ff6575",
            borderRadius: 0,
            padding: 0,
            fontSize: 18,
            fontFamily: "Space Grotesk"
          }}
          onClick={() => {
            setOpenItem(null);
            setAddItem(!addItem);
          }}
        >
          Ny period +
        </Button>
      </div>
      <Row>
        <div>
          <CustomList
            size="small"
            bordered={false}
            split={false}
            dataSource={items}
            renderItem={item => (
              <CustomListItem
                onClick={() => {
                  setOpenItem(item);
                  setAddItem(false);
                }}
                selected={openItem === item}
              >
                {item.title}
              </CustomListItem>
            )}
          />
        </div>
        <div style={{ flexGrow: 1 }}>
          <CalendarContainer
            style={{
              margin: "0",
              // borderLeft: 'none',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0
            }}
          >
            {openItem && (
              <SeasonView
                season={openItem}
                key={openItem.id}
                rules={rules}
                updateSeason={updateSeason}
                /*
                updateSeason={input => {
                  updateSeason({ variables: input });
                }}
                */
                showPeriods
                showTimes
                showRules
              />
            )}
            {addItem && <SeasonView season={openItem} rules={rules} />}
          </CalendarContainer>
        </div>
      </Row>
    </TabContent>
  );
};

const PanelItem = styled.p`
  color: ${props => (props.active ? theme.pegora_coral : "white")};
  font-weight: bold;
  font-size: 16px;
  padding: 0 10px;
  margin: 0;
  cursor: pointer;
`;

const SettingsCollapse = styled.div`
  background-color: ${theme.pegora_navy};
  color: white;
  padding: 20px;
  border-radius: 16px;
`;

const PanelRow = styled.div`
  display: flex;
`;

const PanelIcon = styled(Icon)`
  font-size: 14px;
  position: relative;
  top: 1;
  font-weight: bold;
  margin-left: 4px;
`;

const TabContentContainer = styled.div`
  padding: ${props => (props.open ? "20px 20px 0px 20px" : 0)};
`;

const TabContent = styled.div`
  display: block;
`;

const EditorTabs = ({ configuration, occupancy, setOccupancy, setProfile }) => {
  const [activeTab, setActiveTab] = useState(false);
  if (!configuration) {
    return <p>Loading...</p>;
  }

  const onSetTab = key => {
    activeTab === key ? setActiveTab(false) : setActiveTab(key);
  };
  return (
    <div>
      <SettingsCollapse open={activeTab}>
        <PanelRow>
          <p
            style={{ fontSize: 16, color: "white", margin: 0, marginRight: 10 }}
          >
            Inställningar
          </p>
          <PanelItem onClick={() => onSetTab(1)} active={activeTab === 1}>
            Schema
            <PanelIcon type={activeTab !== 1 ? "down" : "up"} />
          </PanelItem>
          <PanelItem onClick={() => onSetTab(2)} active={activeTab === 2}>
            Priser
            <PanelIcon type={activeTab !== 2 ? "down" : "up"} />
          </PanelItem>
          <PanelItem onClick={() => onSetTab(3)} active={activeTab === 3}>
            Perioder
            <PanelIcon type={activeTab !== 3 ? "down" : "up"} />
          </PanelItem>
          <PanelItem onClick={() => onSetTab(4)} active={activeTab === 4}>
            Regler
            <PanelIcon type={activeTab !== 4 ? "down" : "up"} />
          </PanelItem>
        </PanelRow>
        <TabContentContainer open={activeTab}>
          {activeTab === 1 && (
            <TabContent>
              <Row style={{ alignItems: "flex-start" }}>
                <div style={{ marginRight: 20 }}>
                  <EditorBaseSettingsForm
                    loading={false}
                    baseConfig={configuration.getBaseConfig}
                  />
                </div>
                <div>
                  <Row>
                    <CustomLabel>Välj beläggning</CustomLabel>
                    <Select
                      defaultValue={occupancy}
                      style={{ width: 180 }}
                      onChange={val => {
                        setOccupancy(val);
                      }}
                    >
                      {[...Array(11).keys()].map(val => (
                        <Option key={val} value={val}>
                          {val * 10}%
                        </Option>
                      ))}
                    </Select>
                  </Row>
                  <SelectProfileContainer>
                    <div style={{ marginTop: 20 }}>
                      <Row>
                        <CustomLabel>Visa bokningsschema för:</CustomLabel>
                        <Select
                          style={{ width: 180 }}
                          onChange={profile => {
                            setProfile(profile);
                          }}
                          placeholder="Välj profil"
                        >
                          <Option value={null}>Välj profil</Option>
                          {profiles.map(profile => (
                            <Option
                              value={profile.firstName}
                              key={profile.firstName + profile.lastName}
                            >
                              {profile.firstName} ({profile.age}, hcp{" "}
                              {profile.hcp})
                            </Option>
                          ))}
                        </Select>
                      </Row>
                    </div>
                  </SelectProfileContainer>
                </div>
              </Row>
            </TabContent>
          )}
          {activeTab === 2 && (
            <TabContent>
              <PriceConfigForm
                loading={false}
                priceConfig={configuration.getPriceConfig}
              />
              <div style={{ display: "flex" }}>
                <LanguageForm
                  language={configuration.getLanguage}
                />
              </div>
            </TabContent>
          )}
          {activeTab === 3 && (
            <SeasonsTab
              items={configuration.listSeasons}
              periods={configuration.listPeriods}
              rules={configuration.listRules}
            />
          )}
          {activeTab === 4 && <RulesTab items={configuration.listRules} />}
        </TabContentContainer>
      </SettingsCollapse>
    </div>
  );
};
const Calendar = ({ configuration, loading }) => {
  const [date, setDate] = useState(moment().utc(false));
  const [profile, setProfile] = useState(null);
  const [tab, setTab] = useState("1");

  const [occupancy, setOccupancy] = useState(6);

  console.log(tab);

  return (
    <div>
      <Container>
        <EditorTabs
          {...{ configuration, occupancy, setOccupancy, setProfile, tab }}
        />

        <CalendarContainer>
          {loading ? <LoadingOverlay /> : null}
          <Tabs
            forceRender={false}
            animated={false}
            onChange={key => {
              setTab(key);
            }}
            tabBarStyle={{ border: "none" }}
            style={{ textAlign: "left", borderRadius: 12 }}
          >
            <TabPane tab="Dagsvy" key="1">
              {tab === "1" && (
                <CalendarDayView
                  setDate={setDate}
                  date={date}
                  occupancy={occupancy}
                  profile={
                    profile && profiles.find(p => p.firstName === profile)
                  }
                  configuration={configuration}
                  // bookableItems={data ? data.startTimeSchedule.bookableItems : []}
                />
              )}
            </TabPane>
            {/* 
          <TabPane tab="Veckovy" key="32">
            <CalendarWeekView
              setDate={setDate}
              date={date}
              occupancy={occupancy}
              configuration={configuration}
              profile={profile && profiles.find(p => p.firstName === profile)}
              // bookableItems={data ? data.startTimeSchedule.bookableItems : []}
            />
           
          </TabPane>
           */}
            <TabPane tab="Månadsvy" key="3">
              {tab === "3" && (
                <CalendarMonthView
                  profile={
                    profile && profiles.find(p => p.firstName === profile)
                  }
                  setDate={setDate}
                  date={date}
                  occupancy={occupancy}
                  configuration={configuration}
                />
              )}
            </TabPane>
            <TabPane tab="Årsvy" key="4">
              {tab === "4" && (
                <CalendarYearView
                  profile={
                    profile && profiles.find(p => p.firstName === profile)
                  }
                  setDate={setDate}
                  date={date}
                  occupancy={occupancy}
                  configuration={configuration}
                />
              )}
              ;
            </TabPane>
            {/* 
          <TabPane tab="Årsvy" key="3">
            <ScheduleYearView setDate={setDate} date={date} data={newData} />
          </TabPane>
          */}
          </Tabs>
        </CalendarContainer>
      </Container>
    </div>
  );
};

export default Calendar;
