import React, {useState} from "react";
import styled from "styled-components";
import CampaignBlock from "components/CampaignBlock";
import { Button } from "@golfersweden/pegora-ui";
import { useQuery } from '@apollo/react-hooks';
import { GET_EVENTS } from 'graphql/Queries';
import PageHeader from 'elements/PageHeader';
import Page from 'elements/Page';
import PageContent from 'elements/PageContent';

const EmptyPlaceholder = styled.p`
  text-align: center;
  font-style: italic; 
  padding: 40px;
`;
const EventsPage = () => {
  const [createNew, setCreateNew] = useState(false);
  const {loading, data} = useQuery(GET_EVENTS);
  console.log(loading);
  return (
    <Page>
      <PageHeader title="Events" actionButtons={[
        <Button
        type="primary"
        icon="plus"
        key="2"
        onClick={() => setCreateNew(!createNew)}
      >
        Nytt event
      </Button>

      ]} />
      <PageContent>
      <p></p>
      <p>
        Här kan du lägga till specialperioder som bara gäller mellan vissa fasta
        datum.
      </p>

      <p>
        Till exempel kanske ni har två veckor under högsäsong där det är öppet
        några timmar extra, eller kan ta emot mindre eller färre spelare per tid
        under en viss period.
      </p>
      {createNew && (
        <CampaignBlock
          startExpanded
          period={
            {
              title: '',
              schedules: [
                {
                  overrides: {},
                  times: [
                  ]
                }
              ]
            }
          }
      />
      )}
      {data && data.listEvents && data.listEvents.length ? (
        data.listEvents.map(campaign => (
        <CampaignBlock
          period={campaign}
          key={campaign.id}
        />
        ))
      ) : (
        <EmptyPlaceholder>
          Du har inga kampanjer ännu. Klicka på "Skapa kampanj" för att göra en
          ny!
        </EmptyPlaceholder>
      )}
      </PageContent>
    </Page>
  );
};

export default EventsPage;
