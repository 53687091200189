import React, { useEffect } from "react";
import { Form, Select } from "@golfersweden/pegora-ui";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_LANGUAGE } from "graphql/Mutations";
import Label from '../CustomLabel';
const { Option } = Select;

const StyledForm = styled(Form)`
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

const LanguageForm = ({ form, language, onSave }) => {
  const { getFieldDecorator } = form;

  const [updateLanguage] = useMutation(UPDATE_LANGUAGE);

  useEffect(() => {
    if (language) {
      form.setFieldsValue({
        code: language.code,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const submitForm = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      setTimeout(() => {
        onSubmit();
      }, 100);
    });
  };

  const onSubmit = async e => {
    // const values = form.getFieldsValue();
    console.log(language.id);
    updateLanguage({
      variables: {
        input: {
          id: language.id,
          code: form.getFieldsValue().code
        }
      }
    });
  };

  return (
    <React.Fragment>
      <StyledForm onSubmit={onSubmit}>
        <Row>
          <Label>Välj valuta</Label>
          <StyledFormItem>
            {getFieldDecorator("code", { onChange: submitForm })(
              <Select style={{width: 200}}>
                <Option value="en-US">US-dollar</Option>
                <Option value="sv-SE">Svenska kronor</Option>
              </Select>
            )}
          </StyledFormItem>
        </Row>
      </StyledForm>
    </React.Fragment>
  );
};

export default Form.create({ name: "edit_language_form" })(LanguageForm);
