import React, { useState } from "react";
import styled from "styled-components";
import {
  Select,
  Button,
  TimePicker,
} from "@golfersweden/pegora-ui";
import moment from "moment";
import week_days from "data/week_days.json";
import RoundedRow from 'elements/RoundedRow';

const { Option } = Select;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const Label = styled.p`
  font-size: 14px;
  margin: 0;
  margin-right: 20px;
`;

const CampaignBlockTime = ({ schedule, onSave, onDelete, isOpen, onClose }) => {
  const [open, setOpen] = useState(isOpen);
  const [daysTag, setDaysTag] = useState(schedule && schedule.daysTag);
  const [startHour, setStartHour] = useState(schedule && schedule.startHour);
  const [startMinute, setStartMinute] = useState(
    schedule && schedule.startMinute
  );
  const [endHour, setEndHour] = useState(schedule && schedule.endHour);
  const [endMinute, setEndMinute] = useState(schedule && schedule.endMinute);
  const [startTime] = useState(schedule && schedule.startTme);
  const [endTime] = useState(schedule && schedule.endTime);

  const getObject = () => {
    const newSchedule = schedule ? schedule : {};
    return {
      ...newSchedule,
      startHour,
      startMinute,
      endHour,
      endMinute,
      startTime,
      endTime,
      daysTag,
      id:
        schedule && schedule.id
          ? schedule.id
          : Math.random()
              .toString(36)
              .substring(2, 15) +
            Math.random()
              .toString(36)
              .substring(2, 15)
    };
  };
  return (
    <RoundedRow>
      {open ? (
        <Row style={{ justifyContent: "stretch", alignItems: "center" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Row>
              <Label style={{ marginRight: 5 }}>Öppet</Label>
              <Select
                placeholder="Välj dag"
                defaultValue={daysTag}
                style={{ width: 110 }}
                onChange={val => {
                  setDaysTag(val);
                }}
              >
                {Object.keys(week_days).map(tag => (
                  <Option value={tag}>{week_days[tag].sv}</Option>
                ))}
              </Select>
              <Label style={{ paddingLeft: 5, marginRight: 5 }}>mellan</Label>
              <TimePicker
                defaultValue={moment(
                  `${startHour}:${startMinute ? startMinute : "0"}`,
                  "H:mm"
                )}
                format={"HH:mm"}
                onChange={(m, timestring) => {
                  setStartHour(m.hour());
                  setStartMinute(m.minutes());
                }}
                addon={() => <p>Välj öppningstid</p>}
              />
              <Label style={{ paddingLeft: 5, marginRight: 5 }}>och</Label>
              <TimePicker
                defaultValue={moment(
                  `${endHour}:${endMinute ? endMinute : "0"}`,
                  "H:mm"
                )}
                onChange={(m) => {
                  setEndHour(m.hour());
                  setEndMinute(m.minutes());
                }}
                format={"HH:mm"}
                addon={() => <p>Välj stängningstid</p>}
              />
            </Row>
          </div>

          {schedule && schedule.id && (
            <Button
              onClick={() => {
                onDelete(schedule);
                setOpen(!open);
              }}
              style={{
                marginLeft: "6px",
                marginRight: "6px"
              }}
              shape="circle"
              flatten
              color="warning"
              type="danger"
              icon="delete"
            />
          )}

          <Button
            onClick={() => {
              onClose && onClose();
              setOpen(false);
            }}
            style={{
              marginLeft: "6px",
              marginRight: "6px"
            }}
            shape="circle"
            flatten
            type="ghost"
            icon="close"
          />
          <Button
            onClick={() => {
              const obj = getObject();
              onSave(obj);
              setOpen(!open);
            }}
            shape="circle"
            flatten
            type="ghost"
            icon="check"
          />
        </Row>
      ) : (
        <Row
          style={{
            alignItems: "center",
            justifyContent: "stretch",
            textAlign: "left"
          }}
        >
          <p style={{ flexBasis: "100%", margin: 0 }}>
            {daysTag && (
              <React.Fragment>
                Öppet {week_days[daysTag].sv.toLowerCase()}
                {" mellan "}
                {startHour}:{String(startMinute).padStart(2, "0")}
                {" och "}
                {endHour}:{String(endMinute).padStart(2, "0")}
                {schedule && schedule.startTime && (
                  <React.Fragment>
                    {" från "}
                    {moment(schedule.startTime).format("DD MMM")}

                    {schedule.endTime && (
                      <React.Fragment>
                        {" till "}
                        {moment(schedule.endTime).format("DD MMM")}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </p>
          <Button
            onClick={() => setOpen(!open)}
            style={{ alignSelf: "flex-end" }}
            shape="circle"
            flatten
            type="ghost"
            icon="edit"
          />
        </Row>
      )}
    </RoundedRow>
  );
};

export default CampaignBlockTime;
