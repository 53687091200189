const typeDefs = `
type BaseConfig {
  id: ID!
  title: String
  maxEntries: Int
  resolutionInterval: Int
  resolutionType: String
}

type Language {
  id: ID!
  code: String
}

type PriceConfig {
  id: ID!
  dynamic: Boolean
  basePrice: Int
  limitIndex: Int
  percentHigh: Int
  percentLow: Int
}

type Schedule {
  id: ID!
  startHour: Int
  endHour: Int
  startMinute: Int
  endMinute: Int
  daysTag: String
  weekOfMonths: String
}

type Expression {
  inverted: Boolean
  ruleType: String
  value: String
  operator: String
  conjunction: String
}

type Rule {
  id: ID!
  name: String
  description: String
  expressions: [Expression]
}

type Period {
  id: ID!
  title: String!
  schedules: [Schedule]
  rules: [Rule]
}

type RulePeriod {
  id: ID!
  rule: String
  schedules: [Schedule]
}

type Event {
  id: ID!
  title: String
  color: String
  description: String
  rules: [Rule]
  timePeriods: [TimePeriod]
  schedules: [Schedule]
}

type Season {
  id: ID!
  title: String!
  description: String
  color: String
  timePeriods: [TimePeriod]
  periods: [Period]
  rules: [Rule]
  rulePeriods: [RulePeriod]
}

type TimePeriod {
  from: String!
  to: String!
}

type Query {
  listPeriods: [Period]
  listRules: [Rule]
  listEvents: [Event]
  listSeasons: [Season]
  getBaseConfig: BaseConfig!
  getPriceConfig: PriceConfig!
  getPeriod(id: ID!): Period!
  getSeason(id: ID!): Season!
  getLanguage: Language!
}

type Config {
  listPeriods: [Period]
  listRules: [Rule]
  listEvents: [Event]
  listSeasons: [Season]
  getBaseConfig: BaseConfig!
  getPriceConfig: PriceConfig!
  getPeriod(id: ID!): Period!
  getSeason(id: ID!): Season!
  getLanguage: Language!
}


type Mutation {
  updateBaseConfig(input: UpdateBaseConfigInput): BaseConfig
  updatePriceConfig(input: UpdatePriceConfigInput): PriceConfig

  createSchedule(input: ScheduleCreateInput): Schedule
  updateSchedule(input: ScheduleUpdateInput): Schedule
  deleteSchedule(input: ScheduleDeleteInput): Schedule

  createPeriod(input: PeriodCreateInput): Period
  updatePeriod(input: PeriodUpdateInput): Period
  deletePeriod(input: PeriodDeleteInput): Period

  createSeason(input: SeasonCreateInput): Season
  updateSeason(input: SeasonUpdateInput): Season
  deleteSeason(input: SeasonDeleteInput): Season

  createEvent(input: EventCreateInput): Event
  updateEvent(input: EventUpdateInput): Event
  deleteEvent(input: EventDeleteInput): Event

  createRule(input: RuleCreateInput): Rule
  updateRule(input: RuleUpdateInput): Rule

  updateLanguage(input: UpdateLanguageInput): Language

  loadConfig(input: LoadConfigInput!): Boolean
}

input UpdateBaseConfigInput {
  id: ID!
  maxEntries: Int
  title: String
  resolutionInterval: Int
  resolutionType: String
}

input UpdatePriceConfigInput {
  id: ID!
  dynamic: Boolean
  basePrice: Int
  limitIndex: Int
  percentHigh: Int
  percentLow: Int
}

input ScheduleCreateInput {
  id: ID!
  startHour: Int
  endHour: Int
  startMinute: Int
  endMinute: Int
  daysTag: String
}

input ScheduleUpdateInput {
  id: ID!
  startHour: Int
  endHour: Int
  startMinute: Int
  endMinute: Int
  daysTag: String
}

input ScheduleDeleteInput {
  id: ID!
}

input PeriodCreateInput {
  title: String
}

input PeriodUpdateInput {
  id: ID!
  title: String
  schedules: String
  rules: String

}
input PeriodDeleteInput {
  id: ID!
}

input SeasonCreateInput {
  title: String
  timePeriods: String
  rules: String
  periods: String
}

input SeasonUpdateInput {
  id: ID!
  title: String
  description: String
  color: String
  timePeriods: String
  rules: String
  periods: String
  rulePeriods: String
}

input SeasonDeleteInput {
  id: ID!
}

input EventCreateInput {
  title: String
}

input EventUpdateInput {
  id: ID!
  title: String
  color: String
  description: String
  rules: String
  timePeriods: String
  schedules: String
}

input EventDeleteInput {
  id: ID!
}

input UpdateLanguageInput {
  id: ID!
  code: String
}

input RuleUpdateInput {
  id: ID!
  name: String
  description: String
  expressions: String
}

input RuleCreateInput {
  name: String
  description: String
  expressions: String
}

input LoadConfigInput {
  config: String
}
`;






export default typeDefs;
