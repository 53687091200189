
import ApolloClient from "apollo-boost";
// import { createHttpLink } from 'apollo-link-http';
// import { setContext } from 'apollo-link-context';
// import { InMemoryCache } from 'apollo-cache-inmemory';

const URI = 'https://api.develop.pegora.io/graphql';



const customClient = new ApolloClient({
  uri: URI,
});

export default customClient;






/*
const httpLink = createHttpLink({
  uri: URI,
});

const languageLink = setContext((_, { headers }) => {
  // get the  token from local storage if it exists
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'Accept-Language': 'sv-SE'
    }
  }
});

const customClient = new ApolloClient({
  link: httpLink.concat(languageLink),
  cache: new InMemoryCache()
  // uri: URI,
});

export default customClient;
*/
