import { useQuery } from "@apollo/react-hooks";
// import SchedulePeriod from "components/ScheduleEditor/PeriodItem";
import week_days from "data/week_days";
import { GET_ALL } from "graphql/Queries";
import {
  getRulesInTimePeriod,
  getSchedulesInTimePeriod
} from "helpers/objectParsers";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { theme } from "@golfersweden/pegora-ui";

const PersonHeader = styled.p`
  margin: 0;
  color: red;
`;
/*
const Rule = styled.p`
  margin: 0;
`;
*/
const RuleContainer = styled.div`
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #eee;
`;

const Pill = styled.p`
  padding: 6px 12px;
  display: inline-block;
  text-align: left;
  border-radius: 16px;
  font-size: 12px;
  color: white;
  clear: both;
  background-color: ${theme.pegora_navy};
`;

const Dot = styled.div`
  border-radius: 7px;
  height: 14px;
  width: 14px;
  margin-right: 1px;
  border: 1px solid ${theme.pegora_coral};
  background-color: ${props => (props.filled ? theme.pegora_coral : "none")};
`;

const PriceLabel = styled.p`
  font-family: Space Grotesk;
  font-size: 26px;
  color: ${theme.pegora_navy};
`;

const TimeLabel = styled.p`
  font-size: 16px;
  color: ${theme.pegora_navy};
`;

const DotContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Bar = styled.div`
  width: 100%;
  background-color: ${props => (props.color ? props.color : "#BBB")};
  color: white;
  padding: 2px;
`;

const Header = styled.p`
  font-size: 16px;
`;

const isDayWithin = (date, from, to, dayNumber) => {
  var compareDate = moment(date).startOf("month");
  // compareDate.set("date", dayNumber);
  var startDate = moment(from, "YYYY-MM-DD");
  var endDate = moment(to, "YYYY-MM-DD");

  return compareDate.isBetween(startDate, endDate, "hour", "[]");
};

const TimeBlockPopup = ({ item, profile, isDollar }) => {
  const { data } = useQuery(GET_ALL);
  let periods = [];

  const dayOfWeek = moment(item.startTime).day();

  const activeRules = getRulesInTimePeriod(item.startTime, item.endTime, data);
  const activeSchedules = getSchedulesInTimePeriod(item.startTime, null, data);

  if (activeSchedules && activeSchedules.seasons) {
    activeSchedules.seasons.forEach(season => {
      season.timePeriods.forEach(timePeriod => {
        if (
          isDayWithin(item.startTime, timePeriod.from, timePeriod.to, dayOfWeek)
        ) {
          let newPeriods = JSON.parse(JSON.stringify(season.periods));
          newPeriods = newPeriods.map(newPeriod => {
            newPeriod.schedules = newPeriod.schedules.filter(time => {
              return week_days[time.daysTag].dayArray.includes(dayOfWeek);
            });
            return newPeriod;
          });
          periods = [...periods, ...newPeriods];
        }
      });
    });
  }

  return (
    <div style={{ textAlign: "center" }}>
      <TimeLabel>{moment(item.startTime).format("HH:mm")}</TimeLabel>
      <DotContainer>
        {[...Array(item.maxEntries)].map((e, i) => {
          return <Dot key={i} filled={i < item.entries} />;
        })}
      </DotContainer>
      <div>
        {item.rules.length ? (
          <RuleContainer>
            <PersonHeader>
              <b>{profile.firstName}</b> får inte boka tiden:
            </PersonHeader>
            {item.rules.map(rule => (
              <div>
                <Pill>{rule.name}</Pill>
              </div>
            ))}
          </RuleContainer>
        ) : (
          <p></p>
        )}
        <div style={{ marginBottom: 10 }}>
          <Header>Aktiva tidsinställningar</Header>
          {activeSchedules.seasons.length > 0 && (
            <div>
              {activeSchedules.seasons.map(season => (
                <div>
                  <div>
                    <Pill color={season.color} key={season.id}>
                      {season.title}
                    </Pill>
                  </div>
                  {/* periods.length ? (
                  periods.map(period => {
                    return <div><Pill>Tidsperiod Implementeras</Pill></div>
                    // return <SchedulePeriod period={period} />;
                  })
                ) : (
                  <p>Perioden har inga öppettider idag</p>
                )*/}
                </div>
              ))}
            </div>
          )}
          {activeSchedules.events.length > 0 && (
            <div>
              <h5>Events</h5>
              {activeSchedules.events.map(event => (
                <Bar color={event.color} key={event.id}>
                  {event.title}
                </Bar>
              ))}
            </div>
          )}
        </div>
        <Header>Aktiva regler</Header>
        {activeRules.map(rule => (
          <div>
          <Pill>{rule.name}</Pill>
          </div>
        ))}
      </div>
      <div>
        {!isDollar ? (
          <PriceLabel>
            {parseInt(item.price, 10).toString()}{" "}
            <span style={{ fontSize: 20 }}>kr</span>
          </PriceLabel>
        ) : (
          <PriceLabel>
            <span style={{ fontSize: 20 }}>$</span>
            {parseInt(item.price, 10).toString()}
          </PriceLabel>
        )}
      </div>
    </div>
  );

  /*
  let periods = [];

  const dayOfWeek = moment(date).day();

  
  data && data.seasons.forEach(season => {
    season.timePeriods.forEach(timePeriod => {
      if (isDayWithin(date, timePeriod.from, timePeriod.to, day)) {
        let newPeriods = JSON.parse(JSON.stringify(season.periods));
        newPeriods = newPeriods.map(newPeriod => {
          newPeriod.schedules = newPeriod.schedules.filter(
            time => {
              return week_days[time.daysTag].dayArray.includes(dayOfWeek);
            }
          );
          return newPeriod;
        });
        periods = [...periods, ...newPeriods];
      }
    });
  });

  return (
    <div>
      {data && data.seasons.map(season => {
        return season.timePeriods.map(timePeriod => {
          const isBetween = isDayWithin(
            date,
            timePeriod.from,
            timePeriod.to,
            day
          );
          if (isBetween) {
            return (
              <Bar color={season.color} key={day}>
                {season.title}
              </Bar>
            );
          } else {
          }
        });
      })}
      <p>Tidsregler som påverkar dagen:</p>
      {periods.length ? periods.map(period => {
        return <SchedulePeriod period={period} />;
      }) : (<p>Perioden har inga öppettider idag</p>)}
    </div>
  );
  */
};

export default TimeBlockPopup;
