import React, { useState } from "react";
import styled from "styled-components";
import { Button  } from "@golfersweden/pegora-ui";
import ScheduleSeasonForm from "./forms/EditorSeasonForm";
// import ScheduleTimeBlock from "components/ScheduleTimeBlock";
// import SchedulePeriod from "components/ScheduleEditor/PeriodItem";
import RoundedRow from "elements/RoundedRow";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;


const ScheduleBlockTitle = styled.p`
  font-weight: bold;
  flex-basis: 100%;
  color: white;
  margin: 0;
`;

const ScheduleBlockSubtitle = styled.p`
  flex-basis: 100%;
  color: white;
  font-size: 12px;
  margin: 0;
`;

const EditButton = styled(Button)`
  color: yellow;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
`;

/*
const RuleContainer = styled.div`
  background-color: #ddd;
  color: black;
  display: flex;
  align-items: center;
  padding: 1px;
  margin: 1px;
  justify-content: space-between;
`;

*/
const ScheduleSeason = ({
  season,
  startExpanded,
  showRules,
  showTimes,
  showPeriods,
  onUpdate
}) => {
  const [open, setOpen] = useState(startExpanded ? startExpanded : false);

  return (
    <RoundedRow color={season.color ? season.color : "#666"}>
      <EditButton
        shape="circle"
        flatten
        onClick={() => {
          setOpen(!open);
        }}
        type="ghost"
        icon="edit"
      />

      {open ? (
        <React.Fragment>
          <ScheduleSeasonForm
            season={season}
            onSave={() => {
              setOpen(false);
            }}
          />
        </React.Fragment>
      ) : (
        <div>
          <Row style={{ flexBasis: "100%", display: "flex", padding: 10 }}>
            <div>
              <ScheduleBlockTitle>{season.title}</ScheduleBlockTitle>
              <ScheduleBlockSubtitle>
                {season.timePeriods[0].from} - {season.timePeriods[0].to}
              </ScheduleBlockSubtitle>
            </div>
          </Row>
        </div>
      )}

      { /*showRules &&
        season &&
        season.rules &&
        season.rules.map(rule => {
          return <RuleContainer>
          {rule.name}
          <Button style={{alignSelf: 'flex-end'}} onClick={() => {
            const input = {
              ...season,
              rules: JSON.stringify(season.rules.filter(r => r.id !== rule.id)),
              timePeriods: JSON.stringify(season.timePeriods)
            }
            delete input.__typename;
            if(season.periods) {
              input.periods = JSON.stringify(season.periods);
            };

            if(season.schedules) {
              input.schedules = JSON.stringify(season.schedules);
            };

            onUpdate({input});
          }}>x</Button>
          </RuleContainer>;
        })}

      {showTimes &&
        season &&
        season.periods &&
        season.periods.map(period => {
          return period.schedules.map(schedule => {
            return <ScheduleTimeBlock schedule={schedule} editable />;
          });
        })}

      {showPeriods &&
        season &&
        season.periods &&
        season.periods.map(period => (
          <SchedulePeriod period={period} key={period.id} />
        ))*/}
    </RoundedRow>
       
  );
};

export default ScheduleSeason;
