import React from "react";
import "@golfersweden/pegora-ui/dist/bundle.css";
import styled from "styled-components";
import {
  Button,
  Menu,
  Icon,
  Dropdown
} from "@golfersweden/pegora-ui";
import SeasonItem from "components/ScheduleEditor/SeasonItem";
// import EventItem from "components/ScheduleEditor/EventItem";
import PeriodItem from "components/ScheduleEditor/PeriodItem";

import { useQuery, useMutation } from "@apollo/react-hooks";
import PageHeader from "elements/PageHeader";
import Page from "elements/Page";
import PageContent from "elements/PageContent";
import {
  GET_SEASONS,
  GET_PERIODS,
  GET_EVENTS
} from "graphql/Queries";
import { UPDATE_SEASON, UPDATE_EVENT } from "graphql/Mutations";

const { SubMenu } = Menu;

/*
const MenuContainer = styled.div`
  background-color: white;
  z-index: 10;
  display: flex;
  flex-direction: column;
`;
*/

const MenuContainer = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: rgb(250, 250, 250);
  margin-left: 10px;
  padding: 10px;
  flex-basis: 100%;
`;



const Row = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const PeriodsPage = () => {
  const { /*loading: loadingSeasons,*/ data } = useQuery(GET_SEASONS);
  const { /*loading: loadingPeriods,*/ data: periodsData } = useQuery(GET_PERIODS);
  const { /*loading: loadingEvents,*/ data: eventsData } = useQuery(GET_EVENTS);

  const [updateSeason] = useMutation(UPDATE_SEASON);
  const [updateEvent] = useMutation(UPDATE_EVENT);

  const contextMenu = period => {
    const addToSeason = season => {
      const newSeason = JSON.parse(JSON.stringify(season));
      delete newSeason.__typename;

      const input = {
        input: {
          ...newSeason,
          timePeriods: JSON.stringify(season.timePeriods),
          periods: JSON.stringify([...season.periods, period]),
          rules: JSON.stringify(season.rules)
        }
      };

      updateSeason({ variables: input });
    };

    const addToEvent = item => {
      const newItem = JSON.parse(JSON.stringify(item));
      delete newItem.__typename;

      const input = {
        input: {
          ...newItem,
          timePeriods: JSON.stringify(item.timePeriods),
          schedules: JSON.stringify([...item.schedules, period]),
          rules: JSON.stringify(item.rules)
        }
      };

      updateEvent({ variables: input });
    };

    return (
      <Menu>
        <SubMenu
          title="Lägg till i säsong"
          onClick={({ domEvent }) => {
            domEvent.stopPropagation();
          }}
        >
          {data &&
            data.listSeasons &&
            data.listSeasons.map(season => (
              <Menu.Item onClick={() => addToSeason(season)}>
                {season.title}
              </Menu.Item>
            ))}
        </SubMenu>
        <SubMenu
          title="Lägg till i event"
          onClick={({ domEvent }) => {
            domEvent.stopPropagation();
          }}
        >
          {eventsData &&
            eventsData.listEvents &&
            eventsData.listEvents.map(event => (
              <Menu.Item onClick={() => addToEvent(event)}>
                {event.title}
              </Menu.Item>
            ))}
        </SubMenu>
        <Menu.Item>Ta bort</Menu.Item>
      </Menu>
    );
  };

  return (
    <Page>
      <PageHeader title="Redigera schema" />
      <PageContent>
        <Row>
          <MenuContainer>
            <Row>
              <h3 style={{ flexBasis: "100%" }}>Säsonger</h3>
              <Button type="primary" icon="plus">
                Ny säsong
              </Button>
            </Row>

            <React.Fragment>
              {data &&
                data.listSeasons &&
                data.listSeasons.map(season => (
                  <SeasonItem season={season} key={season.id} showPeriods />
                ))}
            </React.Fragment>
          </MenuContainer>
          { /* 
          <MenuContainer>
            <Row>
              <h3 style={{ flexBasis: "100%" }}>Events</h3>
              <Button type="primary" icon="plus">
                Nytt event
              </Button>
            </Row>

            <React.Fragment>
              {data &&
                eventsData &&
                eventsData.listEvents &&
                eventsData.listEvents.map(event => (
                  <EventItem event={event} key={event.id} showPeriods />
                ))}
            </React.Fragment>
          </MenuContainer>
          */}
          <MenuContainer>
            <Row>
              <h3 style={{ flexBasis: "100%" }}>Tidsperioder</h3>
              <Button
                type="primary"
                icon="plus"
                // onClick={() => setAddRule(!addRule)}
              >
                Ny period
              </Button>
            </Row>
            <React.Fragment>
              {periodsData &&
                periodsData.listPeriods &&
                periodsData.listPeriods.map(period => (
                  <PeriodItem
                    period={period}
                    key={period.id}
                    menu={() => (
                      <Dropdown
                        overlay={contextMenu(period)}
                        trigger={["click"]}
                      >
                        <Icon
                          type="setting"
                          onClick={event => {
                            // If you don't want click extra trigger collapse, you can prevent this:
                            event.stopPropagation();
                          }}
                        />
                      </Dropdown>
                    )}
                  />
                ))}
            </React.Fragment>
          </MenuContainer>
          
        </Row>
        {/* 
        <ScheduleContainer>
          <ScheduleCalendar data={bookingItems} date={date} setDate={setDate} />
        </ScheduleContainer>
        */}
      </PageContent>
    </Page>
  );
};

export default PeriodsPage;
