import React from "react";
import styled from "styled-components";
import { theme } from "@golfersweden/pegora-ui";
const OccupancyContainer = styled.div`
  position: relative;
  border-radius: 7px;
  height: 14px;
  width: 100%;
  border: 1px solid ${theme.pegora_coral};
  overflow: hidden;
`;

const Inner = styled.div`
  border-radius: 7px;
  height: 14px;
  position: absolute;
  left: 0;
  top: 0;
  width: ${props => props.width}%;
  background-color: ${theme.pegora_coral};
  border-top-right-radius: ${props => (props.width === 100 ? "7px" : "0px")};
  border-bottom-right-radius: ${props => (props.width === 100 ? "7px" : "0px")};
`;

const OccupancyBar = ({ bookableItems }) => {
  let maxEntries = 0;
  let entries = 0;
  bookableItems.forEach(b => {
    maxEntries += b.maxEntries ? b.maxEntries : 0;
    entries += b.entries ? b.entries : 0;
  });

  return (
    <OccupancyContainer>
      <Inner width={parseInt((entries / maxEntries) * 100, 10)}></Inner>
    </OccupancyContainer>
  );
};

export default OccupancyBar;
