import { Button } from "@golfersweden/pegora-ui";
import styled from "styled-components";

const CalendarSlot = styled(Button)`
  margin: 5px;
  height: 148px;
  padding: 22px;
  border: none;
  text-align: center;
  width: 150px;

  background-color: ${props => (props.bookable ? "none" : "rgba(255,0,0,0.2)")};
  box-shadow: 0px 0px 19px -13px rgba(0, 0, 0, 0.75);
`;

export default CalendarSlot;
