import React from "react";
import { Popover } from "@golfersweden/pegora-ui";

const InformationBox = ({ visible, children, onClose, title, content }) => {
  return (
    <Popover
      content={content}
      trigger="click"
      visible={visible}
      onVisibleChange={(isOpening) => {
        !isOpening && onClose();
      }}
    >
      {children}
    </Popover>
  );
};

export default InformationBox;
