import React, { useEffect } from 'react';
import { 
  Form, 
  Input, 
  Button, 
  Popconfirm 
} from '@golfersweden/pegora-ui';

import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_PERIOD, UPDATE_PERIOD, DELETE_PERIOD } from 'graphql/Mutations';
import { GET_PERIODS } from 'graphql/Queries';

const StyledForm = styled(Form)`
  flex-grow: 1;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:flex-end;
  margin-top: 10px;
`;

const PeriodForm = ({ form, period, onSave }) => {
  const { getFieldDecorator } = form;

  const [createPeriod] = useMutation(CREATE_PERIOD, {
    variables: {input: { ...form.getFieldsValue() }},
    update(cache, {data: {createPeriod: createdPeriod}}) {
      const data = cache.readQuery({ query: GET_PERIODS });
      data.listPeriods.push(createdPeriod);        
      cache.writeQuery({query: GET_PERIODS, data });
    }
  });

  const [updatePeriod] = useMutation(UPDATE_PERIOD, {
    variables:{input: {id: period ? period.id : null, ...form.getFieldsValue()}},
    update(cache,  {data: {updatePeriod: updatedPeriod}}) {  
      const data = cache.readQuery({ query: GET_PERIODS });
      data.listPeriods.map(s => s.id === updatedPeriod.id ? updatedPeriod : s);        
      cache.writeQuery({query: GET_PERIODS, data });
    }
  });
  
  const [deletePeriod] = useMutation(DELETE_PERIOD, {
    variables: {input: { id: period ? period.id : null }},
    update(cache) {
      const { listPeriods } = cache.readQuery({ query: GET_PERIODS });
      cache.writeQuery({
        query: GET_PERIODS,
        data: { listPeriods: listPeriods.filter(s => s.id !== period.id) },
      });
    }
  });

  useEffect(() => {
    if (period) {
      form.setFieldsValue({
        title: period.title,
      });
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  return (
    <React.Fragment>
      <StyledForm onSubmit={ async (e) => {
        e.preventDefault();
        period ? await updatePeriod() : await createPeriod();
        onSave();
      }}>
        <StyledFormItem style={{marginRight: 100}}>
          {getFieldDecorator('title', {
            rules: [{ required: true, message: 'Du måste ange en titel' }]
          })(<Input />)}
        </StyledFormItem>
        <Row>
          {period && (
            <Popconfirm
              title="Är du säker på att du vill ta bort perioden?"
              onConfirm={async () => {
                await deletePeriod();
                onSave();
              }}
              okText="Ta bort"
              placement="left"
              cancelText="Avbryt"
            >
              <a href="/#">Ta bort</a>
            </Popconfirm>
          )}
          <Button type="primary" htmlType="submit" style={{marginLeft: '20px'}}>
            Spara 
          </Button>
        </Row>
      </StyledForm>
    </React.Fragment>
  );
};

export default Form.create({ name: 'period_edit_form' })(PeriodForm);
