import React, { useState } from "react";
import styled from "styled-components";
import { Button, Input, DatePicker } from "@golfersweden/pegora-ui";
import CampaignBlockTime from "./CampaignBlockTime";
import moment from "moment";
import week_days from "data/week_days";
import RoundedRow from "elements/RoundedRow";

const Label = styled.p`
  font-size: 14px;
  margin: 0;
  margin-right: 20px;
  color: white;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const Title = styled.h4`
  font-size: 24px;
  text-align: center;
`;

const ScheduleBlocktSubitle = styled.li`
  color: white;
  margin: 0;
  text-align: left;
`;

const ScheduleBlockTitle = styled.p`
  font-weight: bold;
  color: white;
  margin: 0;
  flex-basis: 100%;
`;

const CampaignBlock = ({
  period,
  startExpanded,
  onSaveTime,
  onDeleteTime,
  onDelete
}) => {
  const [open, setOpen] = useState(startExpanded ? startExpanded : false);
  const [addSchedule, setAddSchedule] = useState(false);

  return (
    <RoundedRow color="#685169">
      {open ? (
        <React.Fragment>
          <Row style={{ flexBasis: "100%", display: "flex", padding: 10 }}>
            <ScheduleBlockTitle>
              <Input
                defaultValue={period.title}
                placeholder="Ange kampanjens namn"
              />
            </ScheduleBlockTitle>
            <Button
              style={{ color: "white" }}
              shape="circle"
              flatten
              onClick={() => {
                setOpen(!open);
              }}
              type="ghost"
              icon="edit"
            />
          </Row>
          <Row style={{ flexBasis: "100%", display: "flex", padding: 10 }}>
            <React.Fragment>
              <Label>Från</Label>
              <DatePicker
                style={{ width: "auto" }}
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD"
                placeholder={"Från"}
              />
              <Label>Till</Label>
              <DatePicker
                style={{ width: "auto" }}
                // defaultValue={moment(to)}
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD"
                placeholder={"Till"}
                onOk={() => {}}
              />
            </React.Fragment>
          </Row>
          <Row>
            <Title
              style={{
                marginTop: 20,
                flexBasis: "100%",
                textAlign: "left",
                color: "white"
              }}
            >
              Öppettider
            </Title>
            <Button
              icon="plus"
              onClick={() => {
                setAddSchedule(true);
              }}
            >
              Lägg till tidsschema
            </Button>
          </Row>
          {addSchedule && (
            <CampaignBlockTime
              onSave={s => {
                onSaveTime(s);
                setAddSchedule(false);
                setOpen(false);
              }}
              onClose={() => setAddSchedule(false)}
              isOpen
            />
          )}

          {period.schedules.map(schedule => {
              return (
                <CampaignBlockTime
                  schedule={schedule}
                  onSave={s => {
                    onSaveTime(s);
                    setAddSchedule(false);
                    setOpen(false);
                  }}
                  onDelete={onDeleteTime}
                />
              );
          })}
        </React.Fragment>
      ) : (
        <div>
          <Row style={{ flexBasis: "100%", display: "flex", padding: 10 }}>
            <ScheduleBlockTitle>
              <Row>
                {period.title}
                <div style={{ width: 200, paddingLeft: 20, fontWeight: 200 }}>
                  {period.timePeriods.map(tp => (
                    <ScheduleBlocktSubitle>
                      {tp.from} till {tp.to}
                    </ScheduleBlocktSubitle>
                  ))}
                </div>
              </Row>
            </ScheduleBlockTitle>
            {onDelete && (
              <Button
                onClick={onDelete}
                style={{
                  marginLeft: "6px",
                  marginRight: "6px"
                }}
                shape="circle"
                flatten
                color="warning"
                type="danger"
                icon="delete"
              />
            )}
            <Button
              style={{ color: "white" }}
              shape="circle"
              flatten
              onClick={() => {
                setOpen(!open);
              }}
              type="ghost"
              icon="edit"
            />
          </Row>

          <ul>
            {period.schedules.map(schedule => {
              const {
                daysTag,
                startMinute,
                endMinute,
                startHour,
                endHour
              } = schedule;

              return (
                <RoundedRow key={`${period.id}_${JSON.stringify(schedule)}`}>
                  <p style={{ flexBasis: "100%", margin: 0 }}>
                    {daysTag && (
                      <React.Fragment>
                        Öppet {week_days[daysTag].sv.toLowerCase()}
                        {" mellan "}
                        {startHour}:{String(startMinute).padStart(2, "0")}
                        {" och "}
                        {endHour}:{String(endMinute).padStart(2, "0")}
                        {schedule && schedule.startTime && (
                          <React.Fragment>
                            {" från "}
                            {moment(schedule.startTime).format("DD MMM")}

                            {schedule.endTime && (
                              <React.Fragment>
                                {" till "}
                                {moment(schedule.endTime).format("DD MMM")}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </p>
                </RoundedRow>
              );
            })}
          </ul>
        </div>
      )}
    </RoundedRow>
  );
};

export default CampaignBlock;
