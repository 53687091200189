import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Select,
  Row,
  Col,
  Input,
  Button,
  theme
} from "@golfersweden/pegora-ui";
import styled from "styled-components";
import CustomInput from "components/ScheduleEditor/CustomInput";
import CustomLabel from "components/ScheduleEditor/CustomLabel";

const { Option } = Select;

const formItemLayout = {
  /*
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
  */
};

const CustomFormItem = styled(Form.Item)`
  width: 100%;
  display: block;
  padding-right: 6px;
  > .ant-form-item-control-wrapper {
    width: 100%;
    display: block;
  }
`;

const RulesForm = ({ form /*, loading, onChange*/, onSubmit, item }) => {
  const { getFieldDecorator } = form;

  const [name, setName] = useState("");
  console.log(name);
  // const [description, setDescription] = useState("");
  const [expressions, setExpressions] = useState(
    item ? item.expressions : [{ subject: "all" }]
  );

  useEffect(() => {
    form.setFieldsValue({
      name: item.name,
      description: item.description,
      [`expressions[0].subject`]: "all",
      [`expressions[1].subject`]: "all",
      [`expressions[2].subject`]: "all",
      [`expressions[3].subject`]: "all"
    });

    item.expressions.forEach((expression, i) => {
      // expression.subject = 'all';
      Object.keys(expression).forEach(key => {
        const expressionKey = `expressions[${i}].${key}`;
        if (expression[key] !== null) {
          form.setFieldsValue({
            [expressionKey]: expression[key].toString()
          });
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const SubjectFormItem = ({ index }) => (
    <div style={{ float: "left", width: 60 }}>
      <CustomFormItem>
        {getFieldDecorator(`expressions[${index}].subject`, {
          /*
          rules: [
            {
              required: true,
              message: "Select an item"
            }
          ]
        */
        })(
          <div>
            <Select
              optionFilterProp="children"
              placeholder="Vem?"
              initialValue="all"
              disabled
              style={{ display: "none" }}
              onChange={value => {
                const newValue = expressions;
                newValue.splice(index, 1, {
                  ...expressions[index],
                  subject: value
                });
                setExpressions(newValue);
              }}
            >
              <Option value="all">Alla</Option>
              <Option value="members">Medlemmar</Option>
              <Option value="guests">Gäster</Option>
            </Select>
            <CustomLabel style={{ marginRight: -20, fontWeight: "bold" }}>
              Alla
            </CustomLabel>
          </div>
        )}
      </CustomFormItem>
    </div>
  );
  // eslint-disable-next-line
  {
    /*
  const InvertedFormItem = ({ index }) => (
    <Col span={6}>
      <CustomFormItem>
        {getFieldDecorator(`expressions[${index}].inverted`, {
          rules: [
            {
              required: true,
              message: "Select an item"
            }
          ]
        })(
          <Select
            optionFilterProp="children"
            placeholder="Som.."
            onChange={value => {
              const newValue = expressions;
              newValue.splice(index, 1, {
                ...expressions[index],
                inverted: value
              });
              setExpressions(newValue);
            }}
          >
            <Option value="false">Som har</Option>
            <Option value="true">Som inte har</Option>
          </Select>
        )}
      </CustomFormItem>
    </Col>
  );
  */
  }

  const PropertyFormItem = ({ index }) => (
    <Col span={6}>
      <CustomFormItem>
        {getFieldDecorator(`expressions[${index}].ruleType`, {
          rules: [
            {
              required: true,
              message: "Select an item"
            }
          ]
        })(
          <Select
            optionFilterProp="children"
            placeholder="Egenskapen.."
            onChange={value => {
              const newValue = expressions;
              newValue.splice(index, 1, {
                ...expressions[index],
                ruleType: value
              });
              setExpressions(newValue);
            }}
          >
            <Option value="age">Ålder</Option>
            <Option value="handicap">Handicap</Option>
            <Option value="membership">Medlemskapstyp</Option>
            <Option value="gender">Kön</Option>
          </Select>
        )}
      </CustomFormItem>
    </Col>
  );

  const GenderFormItem = ({ index }) => (
    <Col span={6}>
      <CustomFormItem>
        {getFieldDecorator(`expressions[${index}].value`, {
          rules: [
            {
              required: true,
              message: "Select an item"
            }
          ]
        })(
          <Select
            placeholder="Välj kön"
            optionFilterProp="children"
            onChange={value => {
              const newValue = expressions;
              newValue.splice(index, 1, {
                ...expressions[index],
                gender: value
              });
              setExpressions(newValue);
            }}
          >
            <Option value="male">Man</Option>
            <Option value="female">Kvinna</Option>
          </Select>
        )}
      </CustomFormItem>
    </Col>
  );

  const ComparisonFormItem = ({ index }) => (
    <CustomFormItem>
      {getFieldDecorator(`expressions[${index}].operator`, {
        rules: [
          {
            required: true,
            message: "Select an item"
          }
        ]
      })(
        <Select
          optionFilterProp="children"
          placeholder="Jämfört.."
          onChange={value => {
            const newValue = expressions;
            newValue.splice(index, 1, {
              ...expressions[index],
              operator: value
            });
            setExpressions(newValue);
          }}
        >
          <Option value="over">Över</Option>
          <Option value="under">Under</Option>
          <Option value="equals">Lika med</Option>
        </Select>
      )}
    </CustomFormItem>
  );

  const ConjunctionFormItem = ({ item, index }) => (
    <Col span={6}>
      <CustomFormItem>
        {getFieldDecorator(`expressions[${index}].conjunction`, {
          initialValue: item.conjunction,
          rules: [
            {
              required: true,
              message: "Select an item"
            }
          ]
        })(
          <Select
            optionFilterProp="children"
            onChange={value => {
              const newValue = expressions;
              newValue.splice(index, 1, {
                ...expressions[index],
                conjunction: value
              });
              setExpressions(newValue);
            }}
          >
            <Option value="and">Och</Option>
            <Option value="or">Eller</Option>
          </Select>
        )}
      </CustomFormItem>
    </Col>
  );

  const ValueFormItem = ({ index }) => (
    <Col span={8}>
      <Row>
        <Col span={12}>
          <ComparisonFormItem index={index} />
        </Col>
        <Col span={12}>
          <CustomFormItem>
            {getFieldDecorator(`expressions[${index}].value`, {
              rules: [
                {
                  required: true,
                  message: "Select an item"
                }
              ]
            })(
              <Input
                type="number"
                placeholder="Värde"
                onChange={e => {
                  const newValue = expressions;
                  newValue.splice(index, 1, {
                    ...expressions[index],
                    value: e.target.value
                  });
                  setExpressions(newValue);
                }}
              />
            )}
          </CustomFormItem>
        </Col>
      </Row>
    </Col>
  );

  const MembershipTypeFormItem = ({ item, index }) => (
    <Col span={6}>
      <CustomFormItem>
        {getFieldDecorator(`expressions[${index}].value`, {
          // initialValue: item.value,
          rules: [
            {
              required: true,
              message: "Select an item"
            }
          ]
        })(
          <Select
            optionFilterProp="children"
            placeholder="Välj medlemskap"
            onChange={value => {
              const newValue = expressions;
              newValue.splice(index, 1, {
                ...expressions[index],
                value: value
              });
              setExpressions(newValue);
            }}
          >
            <Option value="a">Medlemskap A</Option>
            <Option value="b">Medlemskap B</Option>
          </Select>
        )}
      </CustomFormItem>
    </Col>
  );

  const getFormItems = (expression, index) => {
    const items = [];

    if (index > 0) {
      items.push(
        <ConjunctionFormItem
          item={expression}
          key="conjunction"
          index={index}
        />
      );
    }

    items.push(<SubjectFormItem key="subject" index={index} />);

    /*
    form.setFieldsValue({
      [`expressions[${index}].subject`]: "all"
    });
    */

    // if (expression.subject) {
    // items.push(<InvertedFormItem key="inverted" index={index} />);
    // }

    // if (expression.inverted) {
    items.push(<PropertyFormItem key="ruleType" index={index} />);
    // }

    if (expression.ruleType) {
      switch (expression.ruleType) {
        case "age":
          items.push(<ValueFormItem key="age" index={index} />);
          break;
        case "handicap":
          items.push(<ValueFormItem key="handicap" index={index} />);
          break;
        case "membership":
          items.push(
            <MembershipTypeFormItem
              key="membership"
              item={expression}
              index={index}
            />
          );
          break;
        case "gender":
          items.push(<GenderFormItem key="gender" index={index} />);
          break;
        default:
          break;
      }
    }

    return items;
  };

  return (
    <Form
      {...formItemLayout}
      layout="inline"
      style={{ borderRight: "none" }}
      onSubmit={e => {
        form.validateFields((err, values) => {
          const newRule = { ...values };
          delete newRule.subject;
          if (!err) {
            onSubmit({
              ...newRule,
              expressions: expressions.map(e => {
                return {
                  ...e,
                  inverted: false
                };
              })
            });
          } else {
            console.log(err);
          }
        });

        e.preventDefault();
      }}
    >
      <div>
        {/* <Divider orientation="left">Vad?</Divider> */}

        <Row>
          <Col span={12} sm={24} lg={12}>
            <CustomFormItem
              style={{
                boxSizing: "border-box",
                width: "100%",
                paddingRight: 10
              }}
            >
              {getFieldDecorator(`name`, {
                rules: [
                  {
                    required: true,
                    message: "Ange ett namn"
                  }
                ]
              })(
                <CustomInput
                  style={{ width: "100%" }}
                  title={"Namn"}
                  placeholder="Ge din regel ett namn"
                  type="text"
                  onChange={val => {
                    setName(val);
                  }}
                />
              )}
            </CustomFormItem>
          </Col>
          <Col span={12} sm={24} lg={12}>
            <CustomFormItem style={{ boxSizing: "border-box", width: "100%" }}>
              {getFieldDecorator(`description`, {
                rules: [
                  {
                    required: true,
                    message: "Ange en beskrivning"
                  }
                ]
              })(
                <CustomInput
                  placeholder="Ge din regel en beskrivning"
                  title={"Beskrivning"}
                  type="text"
                  onChange={e => {
                    // setName(e.target.value);
                  }}
                />
              )}
            </CustomFormItem>
          </Col>
        </Row>
        <div style={{ display: "flex", marginTop: 40 }}>
          <CustomLabel style={{ paddingTop: 6 }}>Vilka får boka?</CustomLabel>
          <div style={{ width: "100%" }}>
            {expressions.map((item, index) => (
              <Row
                style={{ marginTop: index > 0 ? 10 : 0, marginBottom: 10 }}
                key={`expression-${index}`}
              >
                <Fragment>
                  {getFormItems(item, index).map(input => input)}
                </Fragment>
                <Button
                  type="dashed"
                  icon="plus"
                  size="large"
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    lineHeight: "20px",
                    fontSize: "16px",
                    backgroundColor: theme.pegora_navy
                  }}
                  onClick={() =>
                    setExpressions([...expressions, { conjunction: "and" }])
                  }
                >
                  {" "}
                  och/eller
                </Button>
              </Row>
            ))}

            {/* 
        <Divider orientation="left">När?</Divider>

        <Row style={{ padding: 10 }} gutter={24} key="when"></Row>
        */}
            <Row style={{ padding: 10 }} gutter={24}>
              <Button
                size="large"
                style={{ fontWeight: "bold", lineHeight: "20px" }}
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Spara
              </Button>
            </Row>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default Form.create({ name: "rules_form" })(RulesForm);
