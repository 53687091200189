import React, { useEffect, useState } from "react";
import {
  Form,
  Icon,
  Row as AntRow,
  Col,
  DatePicker,
  Button
} from "@golfersweden/pegora-ui";

import moment from "moment";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import {
  CREATE_SEASON,
  UPDATE_SEASON /*, DELETE_SEASON */
} from "graphql/Mutations";
import { GET_SEASONS } from "graphql/Queries";
import CustomInput from "components/ScheduleEditor/CustomInput";
import CustomLabel from "components/ScheduleEditor/CustomLabel";

const CustomFormItem = styled(Form.Item)`
  width: 100%;
  display: block;
  padding-right: 6px;
  > .ant-form-item-control-wrapper {
    width: 100%;
    display: block;
  }
`;

const StyledForm = styled(Form)`
  flex-grow: 1;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;

const CustomDatePicker = styled(DatePicker)`
  font-size: 15px;
  text-align: center;
  flex-basis: 100%;
  align-self: stretch;
`;

const formItemLayout = {
  /*
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
  */
};

const SeasonForm = ({ form, season, onSave }) => {
  const { getFieldDecorator } = form;

  const [isDirty, setIsDirty] = useState(false);
  console.log(isDirty);

  const onSetDirty = () => {
    setIsDirty(true);
  };

  const [createSeason] = useMutation(CREATE_SEASON, {
    variables: { input: { ...form.getFieldsValue() } },
    update(
      cache,
      {
        data: { createSeason: createdSeason }
      }
    ) {
      const data = cache.readQuery({ query: GET_SEASONS });
      data.listSeasons.push(createdSeason);
      cache.writeQuery({ query: GET_SEASONS, data });
    }
  });

  const [updateSeason] = useMutation(UPDATE_SEASON, {});

  /*
  const [deleteSeason] = useMutation(DELETE_SEASON, {
    variables: { input: { id: season ? season.id : null } },
    update(cache) {
      const { listSeasons } = cache.readQuery({ query: GET_SEASONS });
      cache.writeQuery({
        query: GET_SEASONS,
        data: { listSeasons: listSeasons.filter(s => s.id !== season.id) }
      });
    }
  });
  */

  useEffect(() => {
    if (season) {
      form.setFieldsValue({
        title: season.title,
        description: season.description,
        from: moment(season.timePeriods[0].from),
        to: moment(season.timePeriods[0].to)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [season]);

  return (
    <React.Fragment>
      <StyledForm
        {...formItemLayout}
        onSubmit={async e => {
          e.preventDefault();

          const values = form.getFieldsValue();

          console.log(values);
          const variables = season
            ? {
                input: {
                  ...season,
                  title: values.title,
                  description: values.description,
                  periods: JSON.stringify(season ? season.periods : []),
                  timePeriods: JSON.stringify([
                    {
                      from: values.from.format("YYYY-MM-DD"),
                      to: values.to.format("YYYY-MM-DD")
                    }
                  ]),
                  rulePeriods: JSON.stringify(season.rulePeriods),
                  // timePeriods: JSON.stringify(season.timePeriods),
                  rules: JSON.stringify(season ? season.rules : [])
                }
              }
            : {
                input: {
                  title: values.title,
                  periods: JSON.stringify([]),
                  rules: JSON.stringify([]),
                  timePeriods: JSON.stringify([
                    {
                      from: values.from.format("YYYY-MM-DD"),
                      to: values.to.format("YYYY-MM-DD")
                    }
                  ])
                }
              };
          delete variables.input.__typename;

          season
            ? await updateSeason({ variables })
            : await createSeason({ variables });
          // onSave();
        }}
      >
        <AntRow>
          <Col span={12} sm={24} lg={12}>
            <CustomFormItem
              style={{
                boxSizing: "border-box",
                width: "100%",
                paddingRight: 10,
                marginBottom: 0
              }}
            >
              {getFieldDecorator(`title`)(
                <CustomInput
                  style={{ width: "100%", marginBottom: 0 }}
                  title={"Namn"}
                  placeholder="Ge din period ett namn"
                  type="text"
                  onChange={onSetDirty}
                />
              )}
            </CustomFormItem>
          </Col>
          <Col span={12} sm={24} lg={12}>
            <CustomFormItem
              style={{
                boxSizing: "border-box",
                width: "100%",
                paddingRight: 10,
                marginBottom: 0
              }}
            >
              {getFieldDecorator(`description`)(
                <CustomInput
                  style={{ width: "100%", marginBottom: 0 }}
                  title={"Beskrivning"}
                  placeholder="Ge din period en beskrivning"
                  type="text"
                  onChange={onSetDirty}
                />
              )}
            </CustomFormItem>
          </Col>
        </AntRow>

        {/* 
        <StyledFormItem label="Namn">
          {getFieldDecorator("title", {
            rules: [{ required: true, message: "Du måste ange en titel" }]
          })(<Input />)}
        </StyledFormItem>
        */}
        <Row style={{ alignItems: "center", justifyContent: "flex-start" }}>
          <CustomLabel>Gäller perioden</CustomLabel>
          <StyledFormItem style={{ margin: "0 10px" }}>
            {getFieldDecorator("from", {})(
              <CustomDatePicker
                allowClear={false}
                onChange={onSetDirty}
                suffixIcon={
                  <Icon
                    type="down"
                    style={{ color: "white", fontWeight: "bold" }}
                  />
                }
              />
            )}
          </StyledFormItem>
          <CustomLabel style={{ marginRight: 0 }}>till</CustomLabel>
          <StyledFormItem style={{ margin: "0 0 0 10px" }}>
            {getFieldDecorator("to", {})(
              <CustomDatePicker
                allowClear={false}
                onChange={onSetDirty}
                suffixIcon={
                  <Icon
                    type="down"
                    style={{ color: "white", fontWeight: "bold" }}
                  />
                }
              />
            )}
          </StyledFormItem>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{ marginLeft: "20px", alignSelf: "flex-end" }}
          >
            Spara
          </Button>
        </Row>

        <Row>
          {/*season && (
            <Popconfirm
              title="Är du säker på att du vill ta bort säsongen?"
              onConfirm={async () => {
                await deleteSeason();
                // onSave();
              }}
              okText="Ta bort"
              placement="left"
              cancelText="Avbryt"
            >
              <a href="/#">Ta bort</a>
            </Popconfirm>
            ) */}
        </Row>
      </StyledForm>
    </React.Fragment>
  );
};

export default Form.create({ name: "season_edit_form" })(SeasonForm);
