import styled from "styled-components";

const Page = styled.div`

  display: flex;
  flex-direction: column;
  width: 100%;
  flex-basis: 0 0 1;
  height: 100%;
  align-items: stretch;
  justify-content: stretch;
  text-align: center;
  

`;

export default Page;