import React from "react";

import EditorBaseSettingsForm from "components/ScheduleEditor/forms/EditorBaseSettingsForm";
import { useQuery } from "@apollo/react-hooks";
import PageHeader from "elements/PageHeader";
import Page from "elements/Page";
import PageContent from "elements/PageContent";
import { GET_BASE_CONFIG } from "graphql/Queries";

const PeriodsPage = () => {
  const { loading, data } = useQuery(GET_BASE_CONFIG);

  return (
    <Page>
      <PageHeader title="Schemainställningar" />
      <PageContent>
        {data && data.getBaseConfig && (
          <EditorBaseSettingsForm
            loading={loading}
            baseConfig={data && data.getBaseConfig}
          />
        )}
      </PageContent>
    </Page>
  );
};

export default PeriodsPage;
