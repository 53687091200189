import React from "react";
import styled from "styled-components";

import { GET_ALL } from "graphql/Queries";
import week_days from "data/week_days";
import { useQuery } from "@apollo/react-hooks";

import {
  getActiveRulesForMonth,
  getSchedulesInTimePeriod
} from "helpers/objectParsers";
import moment from "moment";

import OccupancyBar from "./OccupancyBar";
import { theme } from "@golfersweden/pegora-ui";


const isDayWithin = (date, from, to, dayNumber) => {
  var compareDate = moment(date).startOf("month");
  compareDate.set("date", dayNumber);
  var startDate = moment(from, "YYYY-MM-DD");
  var endDate = moment(to, "YYYY-MM-DD");

  return compareDate.isBetween(startDate, endDate, "hour", "[]");
};



const Header = styled.p`
  font-size: 16px;
`;

const TimeLabel = styled.p`
  font-size: 16px;
  color: ${theme.pegora_navy};
`;
const Pill = styled.p`
  padding: 6px 12px;
  display: inline-block;
  text-align: left;
  border-radius: 16px;
  font-size: 12px;
  color: white;
  clear: both;
  background-color: ${theme.pegora_navy};
`;

const PriceLabel = styled.p`
  font-family: Space Grotesk;
  font-size: 26px;
  color: ${theme.pegora_navy};
`;


const MonthBlockPopup = ({
  averagePrice,
  date,
  monthString,
  month,
  bookableItems,
  isDollar
}) => {
  const { data } = useQuery(GET_ALL);

  console.log("Date in DauBLockPopup: ", date);
  const endTime = moment(date)
    .endOf("month");
    
  const startTime = moment(date)
    .startOf("month");

    const dayOfWeek = moment(date).day();

    console.log(startTime.format(), endTime.format());
  const activeRules = getActiveRulesForMonth(startTime, endTime, data);
  let periods = [];
  const activeSchedules = getSchedulesInTimePeriod(date, null, data);

  if (activeSchedules && activeSchedules.seasons) {
    activeSchedules.seasons.forEach(season => {
      season.timePeriods.forEach(timePeriod => {
        if (
          isDayWithin(date, timePeriod.from, timePeriod.to, dayOfWeek)
        ) {
          let newPeriods = JSON.parse(JSON.stringify(season.periods));
          newPeriods = newPeriods.map(newPeriod => {
            newPeriod.schedules = newPeriod.schedules.filter(time => {
              return week_days[time.daysTag].dayArray.includes(dayOfWeek);
            });
            return newPeriod;
          });
          periods = [...periods, ...newPeriods];
        }
      });
    });
  }
  

  return (
    <div style={{ textAlign: "center" }}>
      <TimeLabel>{monthString}</TimeLabel>
      <div style={{ marginBottom: 12 }}>
        <OccupancyBar bookableItems={bookableItems} />
      </div>
      <div style={{ marginBottom: 10 }}>
          <Header>Aktiva tidsinställningar</Header>
          {activeSchedules.seasons.length > 0 && (
            <div>
              {activeSchedules.seasons.map(season => (
                <div>
                  <div>
                    <Pill color={season.color} key={season.id}>
                      {season.title}
                    </Pill>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      <div style={{ marginBottom: 12 }}>
        
        <Header>Aktiva regler</Header>
        {activeRules.map(rule => (
          <div>
          <Pill>{rule.name}</Pill>
          </div>
        ))}
      </div>

      {!isDollar ? (
        <PriceLabel>
          {averagePrice} <span style={{ fontSize: 20 }}>kr</span>
        </PriceLabel>
      ) : (
        <PriceLabel>
          <span style={{ fontSize: 20 }}>$</span>
          {averagePrice}
        </PriceLabel>
      )}
    </div>
  );
};

export default MonthBlockPopup;
