import React, { useEffect } from "react";
import { Form, Select } from "@golfersweden/pegora-ui";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_PRICE_CONFIG } from "graphql/Mutations";
// import { GET_PRICE_CONFIG } from "graphql/Queries";
import Label from "../CustomLabel";
import CustomInput from "components/ScheduleEditor/CustomInput";

const { Option } = Select;

/*
const Label = styled.p`
  font-size: 14px;
  margin: 0;
  margin-right: 20px;
  ${props =>
    props.fill &&
    css`
      flex-basis: 100%;
    `}
`;
*/

const StyledForm = styled(Form)`
  flex-grow: 1;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

const EditorBaseSettingsForm = ({ form, priceConfig, onSave }) => {
  const { getFieldDecorator } = form;

  const [updatePriceConfig] = useMutation(UPDATE_PRICE_CONFIG);

  useEffect(() => {
    if (priceConfig) {
      form.setFieldsValue({
        id: priceConfig.id,
        basePrice: priceConfig.basePrice,
        dynamic: priceConfig.dynamic,
        limitIndex: priceConfig.limitIndex,
        percentHigh: priceConfig.percentHigh,
        percentLow: priceConfig.percentLow
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceConfig]);

  const submitForm = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      setTimeout(() => {
        onSubmit();
      }, 100);
    });
  };

  const onSubmit = async e => {
    const values = form.getFieldsValue();
    updatePriceConfig({
      variables: {
        input: {
          id: priceConfig.id,
          basePrice: parseInt(values.basePrice, 10),
          dynamic: priceConfig.dynamic,
          limitIndex: parseInt(values.limitIndex, 10),
          percentHigh: parseInt(values.percentHigh, 10),
          percentLow: parseInt(values.percentLow, 10)
        }
      }
    });
  };

  return (
    <React.Fragment>
      <StyledForm onSubmit={onSubmit}>
        <Row style={{marginTop: 0, alignItems: 'flex-start'}}>
          <div style={{ marginRight: 20 }}>
            <Row style={{marginTop: 0}}>
              <Label>Baspris (kr)</Label>
              <StyledFormItem>
                {getFieldDecorator("basePrice", { onChange: submitForm })(
                  <CustomInput
                  style={{width: 80}}
                  placeholder="Baspris"
                  title={""}
                  type="number"
                  />
                )}
              </StyledFormItem>
            </Row>
            <Row>
              <Label fill>Begränsningsindex</Label>
              <StyledFormItem>
                {getFieldDecorator("limitIndex", { onChange: submitForm })(
                  <CustomInput
                  style={{width: 80}}
                  placeholder="Begränsningsindex"
                  title={""}
                  type="number"
                  />
                )}
              </StyledFormItem>
            </Row>
          </div>

          <div>
            <Row style={{ alignItems: "center" }}>
              <Label fill>Max höjning</Label>
              <StyledFormItem>
                {getFieldDecorator("percentHigh", { onChange: submitForm })(
                  <Select style={{ width: 100 }}>
                    {[...Array(15).keys()].map(n => {
                      return <Option value={n * 10}>{n * 10} %</Option>;
                    })}
                  </Select>
                )}
              </StyledFormItem>
            </Row>
            <Row>
              <Label fill>Max säkning</Label>
              <StyledFormItem>
                {getFieldDecorator("percentLow", { onChange: submitForm })(
                  <Select style={{ width: 100 }}>
                    {[...Array(15).keys()].map(n => {
                      return <Option value={n * 10}>{n * 10} %</Option>;
                    })}
                  </Select>
                )}
              </StyledFormItem>
            </Row>
            ƒ
          </div>
          <div></div>
        </Row>

        {/* 
        <Row>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: "20px" }}
          >
            Spara
          </Button>
        </Row>
        */}
      </StyledForm>
    </React.Fragment>
  );
};

export default Form.create({ name: "edit_base_settings_form" })(
  EditorBaseSettingsForm
);
