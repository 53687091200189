import styled from 'styled-components';

const RoundedRow = styled.div`
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-bottom: 4px;
  background-color: ${props => props.color ? props.color : "#BBB"};
  padding: 8px;
  position: relative;
`;

export default RoundedRow;