import React from 'react';
import styled from 'styled-components';

const Label = styled.p`
text-align: center;

`;
const Empty = ({children}) => (
  <Label>{children}</Label>
)

export default Empty;