export default [
  {
    firstName: "Donny",
    lastName: "Byström",
    age: 35,
    hcp: 26,
    email: "donny.bystrom@golfersweden.se",
    nationality: "N/A",
    gender: "male",
    membershipIds:  []
  },
  {
    firstName: "Oskar",
    lastName: "Karlsson",
    age: 23,
    hcp: 9,
    nationality: "N/A",
    gender: "male",
    membershipIds:  ["Medlemsskap"]
  },
  {
    firstName: "Elsa",
    lastName: "",
    age: 16,
    hcp: 24,
    gender: 'female',
    membershipIds:  []
  },

  {
    firstName: "Karita",
    lastName: "",
    age: 65,
    hcp: 9,
    gender: 'female',
    membershipIds:  []
  }
];
