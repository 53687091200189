import gql from "graphql-tag";

/*
export const GET_PERIODS = gql`
  query {
    period {
      title
    }
  }
`;
*/

export const GET_ALL = gql`
  {
    listPeriods {
      id
      title
      schedules {
        id
        startHour
        endHour
        startMinute
        endMinute
        daysTag
      }
      rules {
        id
        name
        description
        expressions {
          inverted
          ruleType
          conjunction
          operator
          value
        }
      }
    }
    listRules {
      id
      name
      description
      expressions {
        inverted
        ruleType
        conjunction
        operator
        value
      }
    }
    listEvents {
      id
      title
      description
      timePeriods {
        from
        to
      }
      schedules {
        id
        startHour
        endHour
        startMinute
        endMinute
        daysTag
      }
      rules {
        id
        name
        description
        expressions {
          inverted
          ruleType
          conjunction
          operator
          value
        }
      }
    }
    listSeasons {
      id
      title
      description
      color
      rules {
        id
        name
        description
        expressions {
          inverted
          ruleType
          conjunction
          operator
          value
        }
      }
      rulePeriods {
        id
        rule
        schedules {
          id
          startHour
          endHour
          startMinute
          endMinute
          daysTag
          weekOfMonths
        }
      }
      periods {
        id
        title
        schedules {
          id
          startHour
          endHour
          startMinute
          endMinute
          daysTag
        }
        rules {
          id
          name
          description
          expressions {
            inverted
            ruleType
            conjunction
            operator
            value
          }
        }
      }
      timePeriods {
        from
        to
      }
    }
    getBaseConfig {
      id
      title
      maxEntries
      resolutionInterval
      resolutionType
    }
    getPriceConfig {
      id
      basePrice
      dynamic
      limitIndex
      percentHigh
      percentLow
    }
    getLanguage {
      id
      code
    }
  }
`;

export const GET_PERIODS = gql`
  {
    listPeriods {
      id
      title
      schedules {
        id
        startHour
        endHour
        startMinute
        endMinute
        daysTag
      }
      rules {
        id
        name
        description
        expressions {
          inverted
          ruleType
          conjunction
          operator
          value
        }
      }
    }
  }
`;

export const GET_RULES = gql`
  {
    listRules {
      id
      name
      description
      expressions {
        inverted
        ruleType
        conjunction
        operator
        value
      }
    }
  }
`;

export const GET_EVENTS = gql`
  {
    listEvents {
      id
      title
      description
      timePeriods {
        from
        to
      }
      schedules {
        id
        startHour
        endHour
        startMinute
        endMinute
        daysTag
      }
      rules {
        id
        name
        description
        expressions {
          inverted
          ruleType
          conjunction
          operator
          value
        }
      }
    }
  }
`;

export const GET_SEASONS = gql`
  {
    listSeasons {
      id
      title
      description
      color
      rules {
        id
        name
        description
        expressions {
          inverted
          ruleType
          conjunction
          operator
          value
        }
      }
      rulePeriods {
        id
        rule
        schedules {
          id
          startHour
          endHour
          startMinute
          endMinute
          daysTag
          weekOfMonths
        }
      }
      periods {
        id
        title
        schedules {
          id
          startHour
          endHour
          startMinute
          endMinute
          daysTag
        }
      }
      timePeriods {
        from
        to
      }
    }
  }
`;

export const GET_BASE_CONFIG = gql`
  {
    getBaseConfig {
      id
      title
      maxEntries
      resolutionInterval
      resolutionType
    }
  }
`;

export const GET_PRICE_CONFIG = gql`
  {
    getPriceConfig {
      id
      basePrice
      dynamic
      limitIndex
      percentHigh
      percentLow
    }
  }
`;

export const GET_BOOKINGS = gql`
  {
    listBookings
  }
`;

export const GET_LANGUAGE = gql`
  {
    getLanguage {
      id
      code
    }
  }
`;

export const GET_STARTTIME_SCHEDULE = gql`
  query StartTimeSchedule($input: GetBookableScheduleInput!) {
    startTimeSchedule(input: $input) {
      bookableItems {
        startTime
        endTime
        maxEntries
        entries
        price
        localizedPrice
        bookings {
          profiles {
            firstName
            lastName
          }
        }
        rules {
          name
          description
          profile {
            firstName
            lastName
          }
        }
      }
    }
  }
`;
