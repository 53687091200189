import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Select,
  Form,
  Button,
  TimePicker,
  Icon,
  Checkbox
} from "@golfersweden/pegora-ui";
import moment from "moment";
import week_days from "data/week_days.json";
import { useMutation } from "@apollo/react-hooks";
import generateId from "helpers/generateId";
import CustomLabel from "components/ScheduleEditor/CustomLabel";
import { GET_ALL, GET_PERIODS, GET_SEASONS } from "graphql/Queries";

import {
  CREATE_SCHEDULE,
  UPDATE_SCHEDULE
  // DELETE_SCHEDULE
} from "graphql/Mutations";

const CustomTimePicker = styled(TimePicker)`
  font-size: 15px;
  text-align: center;
  flex-basis: 100%;
  width: 90px;
  align-self: stretch;
  color: white;
`;

const { Option } = Select;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const StyledForm = styled(Form)`
  flex-grow: 1;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const ScheduleTimeForm = ({
  form,
  schedule,
  onSave,
  onDelete,
  prefix,
  weekOfMonths
}) => {
  const { getFieldDecorator } = form;

    const [isDirty, setIsDirty] = useState(false);

  const onSetDirty = () => {
    setIsDirty(true);
  }

  const [useWeekOfMonths, setUseWeekOfMonths] = useState(
    schedule.weekOfMonths ? true : false
  );

  useEffect(() => {
    if (schedule) {
      form.setFieldsValue({
        startTime: moment(
          `${schedule.startHour}:${
            schedule.startMinute ? schedule.startMinute : "0"
          }`,
          "H:mm"
        ),

        endTime: moment(
          `${schedule.endHour}:${
            schedule.endMinute ? schedule.endMinute : "0"
          }`,
          "H:mm"
        ),
        daysTag: schedule.daysTag
      });
    }

    if (weekOfMonths) {
      form.setFieldsValue({
        weekOfMonths: JSON.parse(schedule.weekOfMonths)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule]);

  const [createSchedule] = useMutation(CREATE_SCHEDULE, {
    refetchQueries: [
      { query: GET_ALL },
      { query: GET_PERIODS },
      { query: GET_SEASONS }
    ]
  });

  const [updateSchedule] = useMutation(UPDATE_SCHEDULE, {
    refetchQueries: [
      { query: GET_ALL },
      { query: GET_PERIODS },
      { query: GET_SEASONS }
    ]
  });
  /*
  const [updateSchedule] = useMutation(UPDATE_SCHEDULE);
  */

  const weekOfMonthsOptions = [1, 2, 3, 4, 5].map(n => {
    return {
      label: (
        <CustomLabel
          style={{
            marginRight: 8,
            marginLeft: 8,
            display: "inline-block"
          }}
        >
          {n}
        </CustomLabel>
      ),
      value: n
    };
  });

  return (
    <StyledForm
      onSubmit={async e => {
        e.preventDefault();
        // const input = getPeriodObject();
        console.log(form.getFieldValue("daysTag"));

        
          const variables = {
            input: {
              id: schedule.id ? schedule.id : generateId(),
              daysTag: form.getFieldValue("daysTag") ? form.getFieldValue("daysTag") : schedule.daysTag,
              startHour: parseInt(
                form.getFieldValue("startTime").format("H"),
                10
              ),
              startMinute: parseInt(form.getFieldValue("startTime").format("mm")),
              endHour: parseInt(form.getFieldValue("endTime").format("H")),
              endMinute: parseInt(form.getFieldValue("endTime").format("mm"))
            }
          };
          if(!weekOfMonths) {
          schedule && schedule.id
            ? updateSchedule({ variables })
            : await createSchedule({ variables });
        }

        if(weekOfMonths) {
          weekOfMonths = form.getFieldValue("weekOfMonths");
          console.log("week of months: ", weekOfMonths);
          variables.input.weekOfMonths = JSON.stringify(weekOfMonths);
        }

        onSave(variables);
        setIsDirty(false);
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Row>
          <CustomLabel style={{ marginRight: 8 }}>
            {prefix ? prefix : "Öppet"}
          </CustomLabel>
          <StyledFormItem style={{ marginRight: 8 }}>
            {getFieldDecorator("daysTag", {
              initialValue: "all-days"
            })(
              <Select onChange={onSetDirty} placeholder="Välj dag" style={{ width: 120 }}>
                {Object.keys(week_days).map(tag => (
                  <Option value={tag}>{week_days[tag].sv}</Option>
                ))}
              </Select>
            )}
          </StyledFormItem>
          <CustomLabel style={{ marginRight: 8 }}>mellan</CustomLabel>
          <StyledFormItem style={{ marginRight: 8 }}>
            {getFieldDecorator("startTime", {})(
              <CustomTimePicker
              onChange={onSetDirty}
                allowClear={false}
                suffixIcon={
                  <Icon
                    type="down"
                    style={{ color: "white", fontWeight: "bold" }}
                  />
                }
                format={"HH:mm"}
              />
            )}
          </StyledFormItem>
          <CustomLabel style={{ marginRight: 8 }}>och</CustomLabel>
          <StyledFormItem style={{ marginRight: 8 }}>
            {getFieldDecorator("endTime", {})(
              <CustomTimePicker
                allowClear={false}
                onChange={onSetDirty}
                format={"HH:mm"}
                suffixIcon={
                  <Icon
                    type="down"
                    style={{ color: "white", fontWeight: "bold" }}
                  />
                }
              />
            )}
          </StyledFormItem>

          {(isDirty || !isDirty) &&  (
            <Button
            type="primary"
            size="large"
            disabled={!isDirty}
            htmlType="submit"
            style={{ marginLeft: "20px" }}
          >
            Spara
            </Button>
          )}
          
          
          {onDelete && (
            <Button
              onClick={() => {
                onDelete(schedule);
              }}
              type="primary"
              size="large"
              style={{ marginLeft: "20px" }}
            >
              Ta bort
            </Button>
          )}
        </Row>
        {weekOfMonths && (
          <Row style={{ height: 32, marginTop: 6 }}>
            <div style={{ whiteSpace: "nowrap" }}>
              <Checkbox
                checked={useWeekOfMonths}
                onChange={() => {
                  onSetDirty();
                  setUseWeekOfMonths(!useWeekOfMonths)}
                }
              />
              <CustomLabel
                style={{
                  marginRight: 8,
                  marginLeft: 8,
                  display: "inline-block"
                }}
              >
                Gäller månadsveckor...
              </CustomLabel>
            </div>

            {useWeekOfMonths && (
              <StyledFormItem style={{ marginRight: 8 }}>
                {getFieldDecorator("weekOfMonths", {})(
                  <Checkbox.Group
                  onChange={onSetDirty}
                    options={weekOfMonthsOptions}
                    // defaultValue={[1,2,3,4,5]}
                    // onChange={() => {}}
                  />
                )}
              </StyledFormItem>
            )}
          </Row>
        )}
      </div>
    </StyledForm>
  );
};

export default Form.create({ name: "schedule_time_edit_form" })(
  ScheduleTimeForm
);
