import React from "react";
import { theme } from "@golfersweden/pegora-ui";
import styled from "styled-components";

const Container = styled.div`
  border-bottom: 2px solid ${theme.clear_blue};
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  font-size: 18px;
  margin: 0;
  color: white;
  line-height: 33px;
  margin-right: 10px;
  opacity: 0.8;
  white-space: nowrap;
`;

const Input = styled.input`
  color: white;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-family: "Space Grotesk";
  font-size: 18px;
  flex-basis: 100%;
`;

const CustomInput = ({ title, placeholder, value, onChange, style }) => {
  console.log(value);

  const triggerChange = changedValue => {
    console.log(changedValue);
    // Should provide an event to pass value to Form.
    if (onChange) {
      onChange(changedValue);
    } else {
      console.log("wtf");
    }
  };

  return (
    <div>
      <Container style={style}>
        {title && <Title>{title}*</Title>}
        <Input
        style={style}
          placeholder={placeholder}
          value={value}
          onChange={e => {
            triggerChange(e.target.value);
          }}
        />
      </Container>
    </div>
  );
};

export default CustomInput;
