import styled from 'styled-components';

const Label = styled.p`
font-size: 18px;
margin: 0;
margin-right: 20px;
color: white;
opacity: 0.8;
font-weight: 100;
`;

export default Label;