import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { theme } from "@golfersweden/pegora-ui";
import Popup from "./Popup";
import CalendarSlot from "./CalendarSlot";
import OccupancyBar from "./OccupancyBar";
import DayBlockPopup from './DayBlockPopup';
const PriceLabel = styled.p`
  font-family: Space Grotesk;
  font-size: 26px;
  color: ${theme.pegora_navy};
`;

// import DayBlockPopup from "./DayBlockPopup";

/*
const PriceLabel = styled.p`
font-family: Space Grotesk;
font-size: 26px;
color: ${theme.pegora_navy};
`;
*/


const TimeLabel = styled.p`
  font-size: 16px;
  color: ${theme.pegora_navy};
`;

const DayBlock = ({ day, bookableItems, date, isDollar }) => {
  const [showDetails, setShowDetails] = useState(false);

  const todaysMoment = moment(date).set("date", day);
  // const bookableItems = data.startTimeSchedule.bookableItems;
  /*
  let bookableItems = [];
  if (data && data.startTimeSchedule) {
    bookableItems = data.startTimeSchedule.bookableItems.filter(booking => {
      return (
        moment(booking.startTime).format("MM-DD") ===
        todaysMoment.format("MM-DD")
      );
    });
  }
  */

  const sum = bookableItems
    .map(b => b.price)
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

  const averagePrice = parseInt(sum / bookableItems.length, 10);

  const dayString = todaysMoment.format("Do dddd");
  return (
    <Popup
      visible={showDetails}
      onClose={() => {
        setShowDetails(false);
      }}
      title={moment(date).format("DDDD")}
      content={<DayBlockPopup date={todaysMoment} averagePrice={averagePrice} isDollar={isDollar} dayString={dayString} day={day} bookableItems={bookableItems} />}
      >
  
      <CalendarSlot
        bookable
        onClick={() => setShowDetails(!showDetails)}
      >
        <TimeLabel>{dayString}</TimeLabel>
        <div style={{ marginBottom: 12 }}>
          <OccupancyBar bookableItems={bookableItems} />
        </div>

        
          {!isDollar ? (
            <PriceLabel>{averagePrice} <span style={{ fontSize: 20 }}>kr</span></PriceLabel>
          ) : (
            <PriceLabel><span style={{ fontSize: 20 }}>$</span>{averagePrice}</PriceLabel>
          )}
          
        
      </CalendarSlot>
    </Popup>
  );
};

export default DayBlock;
/*
          const todaysMoment = moment(date).set("date", day);
          let bookableItems = [];
          if (data && data.startTimeSchedule) {
            bookableItems = data.startTimeSchedule.bookableItems.filter(
              booking => {
                return (
                  moment(booking.startTime).format("MM-DD") ===
                  todaysMoment.format("MM-DD")
                );
              }
            );
          }
          return (
            <InformationBox
              key={day}
              visible={openDetailsDay === day}
              onClose={() => {
                setOpenDetailsDay(null);
              }}
              title={
                todaysMoment.format("dddd") + " " + todaysMoment.format("LL")
              }
              content={
                <InformationBoxContent
                  data={{
                    seasons:
                      seasonData && seasonData.listSeasons
                        ? seasonData.listSeasons
                        : []
                  }}
                  date={todaysMoment.format()}
                  day={day}
                />
              }
            >
              <Block
                onClick={() => {
                  setOpenDetailsDay(day);
                }}
              >
                <p>{day}</p>
                <IconContainer>
                  <Icon type="exclamation" color="orange" />
                </IconContainer>
                <OccupancyBar bookableItems={bookableItems} />

                <BarContainer>
                  {seasonData &&
                    seasonData.listSeasons &&
                    seasonData.listSeasons.map(season => {
                      return season.timePeriods.map(timePeriod => {
                        const isBetween = isDayWithin(
                          date,
                          timePeriod.from,
                          timePeriod.to,
                          day
                        );

                        if (isBetween) {
                          return <Bar color={season.color} />;
                        } else {
                          return null;
                        }
                      });
                    })}
                </BarContainer>
              </Block>
            </InformationBox>
          );
*/
