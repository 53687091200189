import React, { useState } from "react";
import "@golfersweden/pegora-ui/dist/bundle.css";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";
import {
  Collapse,
  Button,
  Menu,
  Icon,
  Dropdown
} from "@golfersweden/pegora-ui";
import RulesForm from "components/RulesForm";
import SeasonItem from "components/ScheduleEditor/SeasonItem";
import PeriodItem from "components/ScheduleEditor/PeriodItem";

import { useQuery } from "@apollo/react-hooks";
import PageHeader from "elements/PageHeader";
import Page from "elements/Page";
import PageContent from "elements/PageContent";
import {
  GET_RULES,
  GET_SEASONS,
  GET_PERIODS,
  GET_EVENTS
} from "graphql/Queries";
import { UPDATE_SEASON, UPDATE_PERIOD, UPDATE_EVENT, CREATE_RULE, UPDATE_RULE } from "graphql/Mutations";
const { SubMenu } = Menu;
const { Panel } = Collapse;

/*
const MenuContainer = styled.div`
  background-color: white;
  z-index: 10;
  display: flex;
  flex-direction: column;
`;
*/

const MenuContainer = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: rgb(250, 250, 250);
  margin-left: 10px;
  padding: 10px;
  flex-basis: 40%;
`;

const RulesContainer = styled.div`
  flex-basis: 60%;
`;

const Row = styled.div`
  display: flex;
`;

const Rules = () => {
  const [addRule, setAddRule] = useState(false);
  const { loading: loadingRules, data } = useQuery(GET_RULES);
  const { loading: loadingSeasons, data: seasonsData } = useQuery(GET_SEASONS);
  const { loading: loadingPeriods, data: periodsData } = useQuery(GET_PERIODS);
  const { loading: loadingEvents, data: eventsData } = useQuery(GET_EVENTS);

  console.log(loadingRules, loadingSeasons, loadingPeriods, loadingEvents);

  const [updateRule] = useMutation(UPDATE_RULE, {refetchQueries: [
    { query: GET_RULES }
    ]} );
  const [createRule] = useMutation(CREATE_RULE, {refetchQueries: [
    { query: GET_RULES }
    ]});

  const [updatePeriod] = useMutation(UPDATE_PERIOD, {
    update(
      cache,
      {
        data: { updatePeriod: updatedPeriod }
      }
    ) {
      console.log("update cache", updatedPeriod);
      const sData = cache.readQuery({ query: GET_PERIODS });
      console.log(sData, updatedPeriod);
      sData.listPeriods.map(s =>
        s.id === updatedPeriod.id ? updatedPeriod : s
      );
      console.log("UPDATED THE STUFF", sData);
      cache.writeQuery({ query: GET_PERIODS, sData });
    }
  });

  const [updateEvent] = useMutation(UPDATE_EVENT, {
    update(
      cache,
      {
        data: { updateEvent: updatedEvent }
      }
    ) {
      console.log("update cache", updatedEvent);
      const sData = cache.readQuery({ query: GET_EVENTS });
      console.log(sData, updatedEvent);
      sData.listPeriods.map(s => (s.id === updatedEvent.id ? updatedEvent : s));
      console.log("UPDATED THE STUFF", sData);
      cache.writeQuery({ query: GET_EVENTS, sData });
    }
  });

  const [updateSeason] = useMutation(UPDATE_SEASON, {
    // variables:{input: {id: season ? season.id : null, ...form.getFieldsValue()}},

    update(
      cache,
      {
        data: { updateSeason: updatedSeason }
      }
    ) {
      console.log("update cache", updatedSeason);
      const sData = cache.readQuery({ query: GET_SEASONS });
      console.log(sData, updatedSeason);
      sData.listSeasons.map(s =>
        s.id === updatedSeason.id ? updatedSeason : s
      );
      console.log("UPDATED THE STUFF", sData);
      cache.writeQuery({ query: GET_SEASONS, sData });
    }
  });

  const genExtra = props => (
    <Dropdown overlay={contextMenu(props)} trigger={["click"]}>
      <Icon
        type="setting"
        onClick={event => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
        }}
      />
    </Dropdown>
  );

  const contextMenu = ({ seasons, periods, events, rule }) => {
    const addToPeriod = (rule, period) => {
      console.log("adding to..", rule, period);
      const newPeriod = JSON.parse(JSON.stringify(period));
      delete newPeriod.__typename;

      const input = {
        input: {
          ...newPeriod,
          schedules: JSON.stringify(period.schedules),
          rules: JSON.stringify([...period.rules.map(r => r.id), rule.id])
        }
      };

      console.log(input);
      updatePeriod({ variables: input });
    };
    const addToSeason = (rule, season) => {
      console.log("adding to..", rule, season);
      const newSeason = JSON.parse(JSON.stringify(season));
      delete newSeason.__typename;

      const input = {
        input: {
          ...newSeason,
          timePeriods: JSON.stringify(season.timePeriods),
          periods: JSON.stringify(season.periods),
          rules: JSON.stringify([...season.rules, rule])
          // rules: JSON.stringify([...season.rules.map(r => r.id), rule.id])
        }
      };

      console.log(input);
      updateSeason({ variables: input });
    };

    const addToEvent = (rule, event) => {
      console.log("adding to..", rule, event);
      const newItem = JSON.parse(JSON.stringify(event));
      delete newItem.__typename;

      const input = {
        input: {
          ...newItem,
          timePeriods: JSON.stringify(event.timePeriods),
          schedules: JSON.stringify(event.schedules),
          rules: JSON.stringify([...event.rules, rule])
          // rules: JSON.stringify([...season.rules.map(r => r.id), rule.id])
        }
      };

      console.log(input);
      updateEvent({ variables: input });
    };

    return (
      <Menu>
        <SubMenu
          title="Lägg till i säsong"
          onClick={({ domEvent }) => {
            domEvent.stopPropagation();
          }}
        >
          {seasons &&
            seasons.map(season => (
              <Menu.Item onClick={() => addToSeason(rule, season)}>
                {season.title}
              </Menu.Item>
            ))}
        </SubMenu>
        <SubMenu
          title="Lägg till i event"
          onClick={({ domEvent }) => {
            domEvent.stopPropagation();
          }}
        >
          {events &&
            events.map(event => (
              <Menu.Item onClick={() => addToEvent(rule, event)}>
                {event.title}
              </Menu.Item>
            ))}
        </SubMenu>
        <SubMenu title="Lägg till i period">
          {periods &&
            periods.map(period => (
              <Menu.Item onClick={() => addToPeriod(rule, period)}>
                {period.title}
              </Menu.Item>
            ))}
        </SubMenu>
        <Menu.Item>Lägg till mellan specifika datum...</Menu.Item>
      </Menu>
    );
  };

  return (
    <Page>
      <PageHeader title="Bokningsregler" actionButtons={[]} />
      <PageContent>
        <Row>
          <RulesContainer>
            <Button
              type="primary"
              icon="plus"
              style={{marginBottom: 6}}
              onClick={() => setAddRule(!addRule)}
            >
              Ny regel
            </Button>
            {data && data.listRules ? (
              <Collapse
                defaultActiveKey={["new"]}
                style={{ flexBasis: "100%" }} /*defaultActiveKey={["0"] */
              >
                {addRule && (
                  <Panel header={`Ny regel`} key={"new"}>
                    <RulesForm
                      item={{
                        expressions: [{ subject: "all" }]
                      }}
                      // onChange={val => () => setRules([...rules, val])}
                      onSubmit={val => {
                        console.log("hola");
                        createRule({variables: {input: {
                          ...val,
                          expressions: JSON.stringify(val.expressions),
                        }}});
                        setAddRule(false);
                        console.log(JSON.stringify(val));
                      }}
                    />
                  </Panel>
                )}
                {data.listRules.map((item, index) => (
                  <Panel
                    header={item.name || `Ny regel`}
                    key={index}
                    extra={genExtra({
                      seasons: seasonsData && seasonsData.listSeasons,
                      events: eventsData && eventsData.listEvents,
                      periods: periodsData && periodsData.listPeriods,
                      rule: item
                    })}
                  >
                    <RulesForm
                      item={item}
                      // onChange={val => () => setRules([...rules, val])}
                      onSubmit={val => {
                        console.log("hola");
                        updateRule({variables: {input: {
                          ...val,
                          expressions: JSON.stringify(val.expressions),
                          id: item.id
                        }}});
                        setAddRule(false);
                        console.log(JSON.stringify(val));
                      }}
                    />
                  </Panel>
                ))}
              </Collapse>
            ) : (
              <p>Inga regler än - skapa en ny!</p>
            )}
          </RulesContainer>
          <MenuContainer>
            <h3>Säsonger</h3>
            <React.Fragment>
              {seasonsData &&
                seasonsData.listSeasons &&
                seasonsData.listSeasons.map(season => (
                  <SeasonItem
                    season={season}
                    key={season.id}
                    showRules
                    onUpdate={input => {
                      updateSeason({ variables: input });
                    }}
                  />
                ))}
            </React.Fragment>
            <h3>Events</h3>
            <React.Fragment>
              {eventsData &&
                eventsData.listEvents &&
                eventsData.listEvents.map(event => (
                  <SeasonItem
                    season={event}
                    key={event.id}
                    showRules
                    onUpdate={input => {
                      updateEvent({ variables: input });
                    }}
                  />
                ))}
            </React.Fragment>
            <h3>Tidsperioder</h3>
            <React.Fragment>
              {periodsData &&
                periodsData.listPeriods &&
                periodsData.listPeriods.map(period => (
                  <PeriodItem
                    hideTimes
                    period={period}
                    key={period.id}
                    showRules
                  />
                ))}
            </React.Fragment>
          </MenuContainer>
        </Row>
      </PageContent>
    </Page>
  );
};

const RulesPage = () => {
  return (
    <div>
      <Rules />
    </div>
  );
};

export default RulesPage;
