import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "@golfersweden/pegora-ui";
import SchedulePeriodForm from "./forms/EditorPeriodForm";
import ScheduleTimeBlock from "components/ScheduleTimeBlock";
import RoundedRow from "elements/RoundedRow";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const ScheduleBlockTitle = styled.p`
  font-weight: bold;
  flex-basis: 100%;
  color: white;
  margin: 0;
`;

const EditButton = styled(Button)`
  color: yellow;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
`;

const RuleContainer = styled.div`
  background-color: #ddd;
  color: black;
`;

const SchedulePeriod = ({ period, startExpanded, hideTimes, showRules, menu }) => {
  const [open, setOpen] = useState(startExpanded ? startExpanded : false);
  // const [addSchedule, setAddSchedule] = useState(false);
  return (
    <RoundedRow color={period.color ? period.color : "#666"}>
      <EditButton
        shape="circle"
        flatten
        onClick={() => {
          setOpen(!open);
        }}
        type="ghost"
        icon="edit"
      />
      {menu && menu()}

      {open ? (
        <React.Fragment>
          <SchedulePeriodForm
            period={period}
            onSave={() => {
              setOpen(false);
            }}
          />
          {/* 
          {addSchedule && (
            <ScheduleBlock
              onSave={s => { onSaveTime(s); setAddSchedule(false); setOpen(false); }}
              onClose={() => setAddSchedule(false)}
              isOpen
            />
          )}
          */}
        </React.Fragment>
      ) : (
        <div>
          <Row style={{ flexBasis: "100%", display: "flex", padding: 10 }}>
            <ScheduleBlockTitle>{period.title}</ScheduleBlockTitle>
          </Row>
        </div>
      )}

      {showRules &&
        period &&
        period.rules &&
        period.rules.map(rule => {
          console.log(rule);
          return <RuleContainer>{rule.name}</RuleContainer>;
        })}

      {!hideTimes &&
        period.schedules.map(schedule => {
          return (
            <ScheduleTimeBlock schedule={schedule} period={period} editable />
          );
        })}
    </RoundedRow>
  );
};

export default SchedulePeriod;
