import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "@golfersweden/pegora-ui";
import moment from "moment";
import week_days from "data/week_days";
import ScheduleTimeForm from "./ScheduleEditor/forms/ScheduleTimeForm";
import RoundedRow from "elements/RoundedRow";
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const ScheduleTimeBlock = ({ schedule, period, editable, onSave }) => {
  const { daysTag, startMinute, endMinute, startHour, endHour } = schedule;
  const [open, setOpen] = useState(false);

  return (
    <RoundedRow key={`${schedule.id}_${JSON.stringify(schedule)}`}>
      <Row>
        {open ? (
          <ScheduleTimeForm
            schedule={schedule}
            onSave={() => {
              setOpen(false);
              /* onSave && onSave(); */
            }}
          />
        ) : (
          <p style={{ flexBasis: "100%", margin: 0 }}>
            {daysTag && (
              <React.Fragment>
                Öppet {week_days[daysTag].sv.toLowerCase()}
                {" mellan "}
                {startHour}:{String(startMinute).padStart(2, "0")}
                {" och "}
                {endHour}:{String(endMinute).padStart(2, "0")}
                {schedule && schedule.startTime && (
                  <React.Fragment>
                    {" från "}
                    {moment(schedule.startTime).format("DD MMM")}

                    {schedule.endTime && (
                      <React.Fragment>
                        {" till "}
                        {moment(schedule.endTime).format("DD MMM")}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </p>
        )}

        {editable && (
          <Button
            onClick={() => setOpen(!open)}
            style={{ alignSelf: "flex-end" }}
            shape="circle"
            flatten
            type="ghost"
            icon="edit"
          />
        )}
      </Row>
    </RoundedRow>
  );
};
export default ScheduleTimeBlock;
