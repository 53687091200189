import styled from 'styled-components';

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-self: center;
  text-align: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  max-width: 400px;
`;

export default NavBar;