import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  Select,
  InputNumber,
  Button
} from "@golfersweden/pegora-ui";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_BASE_CONFIG } from "graphql/Mutations";
// import { GET_BASE_CONFIG } from "graphql/Queries";
import CustomNumberPicker from '../CustomNumberPicker';
import Label from '../CustomLabel';

const { Option } = Select;

const StyledForm = styled(Form)`
  flex-grow: 1;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

const EditorBaseSettingsForm = ({ form, baseConfig, onSave }) => {
  const { getFieldDecorator } = form;
  const [customResolutionInterval, setCustomResolutionInterval] = useState(
    false
  );

  const customResolutionInputEl = useRef(null);

  const [updateBaseConfig] = useMutation(UPDATE_BASE_CONFIG, {});

  useEffect(() => {
    if (baseConfig) {
      form.setFieldsValue({
        maxEntries: baseConfig.maxEntries,
        resolutionInterval: baseConfig.resolutionInterval,
        resolutionType: baseConfig.resolutionType
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      setTimeout(() => {
        onSubmit();
      }, 100);
    });
  };

  const onSubmit = async e => {
    // e.preventDefault();
    updateBaseConfig({
      variables: { input: { ...form.getFieldsValue(), id: baseConfig.id } }

      /*
      update(
        cache,
        {
          data: { getBaseConfig: newBaseConfig }
        }
      ) {
        const data = cache.readQuery({ query: GET_BASE_CONFIG });
        data.getBaseConfig = newBaseConfig;
        cache.writeQuery({ query: GET_BASE_CONFIG, data });
      }
      */
    });
  };

  return (
    <React.Fragment>
      <StyledForm onSubmit={onSubmit}>
        <Row static style={{marginTop: 0}}>
          <Label>Hur många spelare kan boka samma tid?</Label>
          <StyledFormItem>
            {getFieldDecorator("maxEntries", {
              onChange: (val, e) => {
                console.log(val, e);
                submitForm();
              }
            })(
              /*
              <Select style={{ width: 130 }}>
                <Option value={1}>en spelare</Option>
                <Option value={2}>två spelare</Option>
                <Option value={3}>tre spelare</Option>
                <Option value={4}>fyra spelare</Option>
                <Option value={5}>fem spelare</Option>
              </Select>
              */
              <CustomNumberPicker
                suffix={<span style={{ marginLeft: 10 }}>spelare</span>}
                valueArray={[1, 2, 3, 4, 5]}
              />
            )}
          </StyledFormItem>
        </Row>
        <Row static>
          <Label>Hur lång tid är det mellan varje slot?</Label>
          <StyledFormItem>
            {getFieldDecorator("resolutionInterval", {
              onChange: val => {
                console.log(val);
                if (val === "custom") {
                  setCustomResolutionInterval(7);
                } else {
                  submitForm();
                }
              }
            })(
              <CustomNumberPicker
                prefix="Var"
                valueArray={[7, 10, 12, 15, 20]}
                suffix={":e"}
              />
              /*
              <Select style={{ width: 110 }}>
                <Option value={7}>Var 7:e</Option>
                <Option value={10}>Var 10:e</Option>
                <Option value={15}>Var 15:e</Option>
                <Option value={20}>Var 20:e</Option>
                <Option value={30}>Var 30:e</Option>
                <Option value="custom">Egen...</Option>
              </Select>
*/
            )}
          </StyledFormItem>
          <StyledFormItem>
            {getFieldDecorator("resolutionType", {
              onChange: submitForm
            })(
              <Select
                style={{ width: 100, marginLeft: 10 }}
              >
                <Option value="DAY">Timme</Option>
                <Option value="HOUR">Minut</Option>
                <Option value="MINUTE">Sekund</Option>
              </Select>
            )}
          </StyledFormItem>
        </Row>
        {customResolutionInterval && (
          <div style={{ paddingRight: "100px", textAlign: "right" }}>
            <InputNumber
              ref={customResolutionInputEl}
              min={1}
              max={60}
              defaultValue={7}
              style={{ width: 60 }}
              onChange={val => {
                if (val) {
                  setCustomResolutionInterval(val);
                }
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                form.setFieldsValue({
                  resolutionInterval: customResolutionInterval
                });
                setCustomResolutionInterval(false);
                submitForm();
              }}
            >
              Ok
            </Button>
          </div>
        )}
      </StyledForm>
    </React.Fragment>
  );
};

export default Form.create({
  id: "edit_base_settings_form",
  name: "edit_base_settings_form"
  /*
  onFieldsChange: () => {
    console.log('fields change');
    const form = document.getElementById('edit_base_settings_form');
    if(form) {
      form.submit();
    }
    
  },
  onValuesChange: () => {
    console.log('values change')
  }
  */
})(EditorBaseSettingsForm);
