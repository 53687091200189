import React from "react";
import PropTypes from "prop-types";
import { ApolloProvider } from "react-apollo";
import { ApolloLink } from "apollo-link";
import { makeExecutableSchema } from "graphql-tools";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { SchemaLink } from "apollo-link-schema";

import typeDefs from "./typeDefs";
import resolvers from "./resolvers";

const executableSchema = makeExecutableSchema({
  typeDefs,
  resolvers
});

const mockLink = new SchemaLink({ schema: executableSchema });

const link = ApolloLink.from([mockLink]);

const GraphQLClient = new ApolloClient({
  link,
  cache: new InMemoryCache({
    // addTypename: false
  })
});

const GraphQLProvider = ({ children }) => (
  <ApolloProvider client={GraphQLClient}>
    <ApolloHooksProvider client={GraphQLClient}>{children}</ApolloHooksProvider>
  </ApolloProvider>
);

GraphQLProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default GraphQLProvider;
