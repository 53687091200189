import moment from "moment";
import week_days from "data/week_days";
import week_enum from "data/week_enum";
import masterConfig from "data/master_config";
import _ from "lodash";
import { parseSchedule } from "helpers/objectParsers";

const generateConfig = ({ config, date, endDate, profile, occupancy, resolution }) => {
  console.log(config);
  if (!date || !config) {
    return;
  }
  const { getPriceConfig, getBaseConfig, listSeasons, listPeriods } = config;

  const { maxEntries, /*resolutionType*/ } = getBaseConfig;

  let {resolutionInterval } = getBaseConfig;

  
  if(resolution === 'month') {
    resolutionInterval *= 20;
  }

  /*

  if(resolution === 'year') {
    resolutionInterval *= 20;
  }

  */

  const ruleConfigurations = [];
  const pricingConfigurations = [];

  const activeRangeEnd = listSeasons[listSeasons.length - 1].periods[0].to;
  const year = masterConfig.years.find(y => y.year === 2019);
  listSeasons.forEach(season => {
    season.periods.forEach(period => {
      /*
      period.rules &&
        period.rules.forEach(periodRule => {
          period.schedules.forEach(schedule => {
            const ruleConfig2 = {
              ..._.omit(
                {
                  ...periodRule,
                  expressions: periodRule.expressions.map(e => {
                    const newE = _.omit(e, "__typename");
                    if(!e.operator) {
                      delete e.operator;
                    }
                    return newE;
                  }
                    
                  )
                },
                "__typename"
              ),
              scheduleConfiguration: {
                ...parseSchedule(schedule),
                startTime: moment(season.timePeriods[0].from).toISOString(),
                endTime: moment(season.timePeriods[0].to).toISOString()
              }
            };
            delete ruleConfig2.id;
            ruleConfigurations.push(ruleConfig2);
          });
        });
        */

      period.schedules.forEach(schedule => {
          const priceConfiguration = {
            ...getPriceConfig,
            dynamic: true,
  
            scheduleConfiguration: {
              ...parseSchedule(schedule),
              startTime: moment(season.timePeriods[0].from),
              endTime: moment(season.timePeriods[0].to)
            }
          };
  
          delete priceConfiguration.__typename;
          delete priceConfiguration.id;
  
          pricingConfigurations.push(priceConfiguration);
      });
    });

    season.rules &&
      season.rules.forEach(rule => {
        const rulePeriods = season.rulePeriods.filter(rulePeriod => {
          return rulePeriod.rule === rule.id
        });
        
        if(rulePeriods.length) {
          season.periods.forEach(period => {
            period.schedules.forEach(schedule => {
              const ruleConfig = {
                ..._.omit(
                  {
                    ...rule,
                    expressions: rule.expressions.map(e => {
                      const newE = _.omit(e, "__typename");
                      if(!e.operator) {
                        delete e.operator;
                      }
                      return newE;
                    })
                  },
                  "__typename"
                ),
                scheduleConfiguration: {
                  ...parseSchedule(rulePeriods[0].schedules[0]),
                  startTime: moment(season.timePeriods[0].from).utc(),
                  endTime: moment(season.timePeriods[0].to).utc()
                  // startTime: "2019-01-01T13:30:27.028Z",
                  // endTime: "2025-12-31T23:59:00.028Z"
                }
              };
              delete ruleConfig.id;
              ruleConfigurations.push(ruleConfig);
  
              const priceConfiguration = {
                ...getPriceConfig,
                dynamic: true,
                scheduleConfiguration: ruleConfig.scheduleConfiguration
              };
              delete priceConfiguration.__typename;
              delete priceConfiguration.id;
  
              pricingConfigurations.push(priceConfiguration);
            });
          });

        }
      });
  });

  const periodIds = listSeasons
    .filter(s => {
      return (
        year.seasons.includes(s.id) &&
        moment(date).isBetween(
          moment(s.timePeriods[0].from),
          moment(s.timePeriods[0].to)
        )
      );
    })
    .map(s => s.periods)
    .flat(1)
    .map(p => p.id);

  const schedules = listPeriods
    .filter(p => periodIds.includes(p.id))
    .map(period => {
      return period.schedules.map(schedule => {
        return _.omit(schedule, "__typename");
      });
    })
    .flat(2);

  const scheduleConfigurations = [...schedules].map(schedule => {
    let val = { ...schedule };
    val.weekdays = week_days[val.daysTag].dayArray.map(d => week_enum[d]);
    delete val["id"];
    delete val["daysTag"];
    return _.omit(val, "__typename");
  });

  const body = {
    startTime: moment(date)
      .startOf("day")
      .format(),
    endTime: endDate ? moment(endDate).endOf("day").format() : moment(date).startOf("day").add(1, "day").format(),
    //endTime: endDate ? moment(endDate).startOf("day").add(1, "day").utc() : moment(date).startOf("day").add(1, "day").utc(),
    bookingScheduleConfiguration: {
      activeRange: {
        start: "2019-01-01T00:00:00.028Z",
        end: activeRangeEnd
      },
      maxEntries,
      resolutionType: resolution === "year" ? "DAY" : "HOUR",
      resolutionInterval: parseInt(resolutionInterval, 10),
      // resolutionType,
      // resolutionType: resolution === 'year' ? "DAY" : resolutionType,
      scheduleConfigurations
    },
    profiles: [],
    //pricingConfigurations: [],
    // ruleConfigurations: []
    pricingConfigurations,
    ruleConfigurations: ruleConfigurations,
    occupancy: occupancy * 10
    /*
    pricingConfigurations: [
      {
        basePrice: 500,
        dynamic: true,
        limitIndex: 2,
        percentHigh: 50,
        percentLow: 50,
        scheduleConfiguration: {
          endHour: 18,
          endMinute: 30,
          startTime: "2019-01-01T22:00:00.000Z",
          startHour: 8,
          startMinute: 0,
          endTime: "2019-12-30T22:00:00.000Z",
          weekdays: ["monday", "tuesday", "wednesday", "thursday", "friday"]
        }
      }
    ],
    */
    // ruleConfigurations: [],
    // pricingConfigurations,

    /*
    priceConfigurations: [
      {
        basePrice: 500,
        dynamic: true,
        limitIndex: 30,
        percentHigh: 50,
        percentLow: 50,
        scheduleConfiguration: {

        }
      }
      */

    //  ...baseConfig
  };

  body.profiles = profile ? [profile] : [];
  return body;
};

export default generateConfig;
