import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Button, DatePicker, Icon, theme } from "@golfersweden/pegora-ui";
import { useQuery } from "@apollo/react-hooks";
import generateConfig from "helpers/generateConfig";
import { GET_STARTTIME_SCHEDULE } from "graphql/Queries";
import ExternalClient from "graphql/ExternalClient";
import NavBar from "../elements/NavBar";
import DayBlock from "../elements/DayBlock";
import _ from "lodash";
import LoadingContainer from '../elements/LoadingContainer';
import Empty from '../elements/Empty';

const { MonthPicker } = DatePicker;


const Container = styled.div`
  padding: 10px;
`;

const ResultContainer = styled.div`
  transition: height 3.5s ease-in;
  display: block;
`;

const CustomDatePicker = styled(MonthPicker)`
  font-size: 15px;
  text-align: center;
  flex-basis: 100%;
  align-self: stretch;
`;

const ScheduleMonthView = ({
  setDate,
  date,
  configuration,
  profile,
  occupancy
}) => {
  // const [openDetailsDay, setOpenDetailsDay] = useState(null);
  // const { data: seasonData } = useQuery(GET_SEASONS);

  const conf = generateConfig({
    resolution: "month",
    config: configuration,
    date: moment(date)
      .startOf("month")
      .startOf("day")
      .format("YYYY-MM-DD"),
    endDate: moment(date)
      .endOf("month")
      .endOf("day")
      .format("YYYY-MM-DD"),
    // date: moment(date).startOf('month').startOf('day').toISOString(),
    //endDate: moment(date).endOf('month').endOf('day').toISOString(),
    occupancy,
    profile
  });

  const { loading, data } = useQuery(GET_STARTTIME_SCHEDULE, {
    variables: { input: conf },
    client: ExternalClient,
    clientState: { defaults: {}, resolvers: {} },
    context: {
      headers: {
        "Accept-Language": configuration && configuration.getLanguage.code
      }
    }
  });

  const onAddDate = () => {
    const newDate = moment(date).add(1, "month");
    setDate(newDate.format("YYYY-MM-DD"));
  };

  const onSubDate = () => {
    const newDate = moment(date).subtract(1, "month");
    setDate(newDate.format("YYYY-MM-DD"));
  };

  const onSetDate = (newMoment, datestring) => {
    setDate(datestring);
  };

  const days = [...Array(moment(date).daysInMonth())].map((o, day) => day + 1);
  let bookableItemsByDay = {};

  let isDollar = false;

  if (data && data.startTimeSchedule) {
    if (data.startTimeSchedule.bookableItems.length) {
      isDollar = data.startTimeSchedule.bookableItems[0].localizedPrice.includes(
        "$"
      );
    }

    bookableItemsByDay = _.groupBy(
      data.startTimeSchedule.bookableItems,
      bookableItem => {
        return moment(bookableItem.startTime).format("D");
      }
    );
  }

  return (
    <Container>
      <NavBar>
        <Button
          style={{ color: theme.clear_blue }}
          type="link"
          icon="left"
          onClick={onSubDate}
        />
        <CustomDatePicker
          onChange={onSetDate}
          value={moment(date)}
          format="MMMM YYYY"
          allowClear={false}
          suffixIcon={
            <Icon type="down" style={{ color: "white", fontWeight: "bold" }} />
          }
        />
        <Button
          style={{ color: theme.clear_blue }}
          type="link"
          icon="right"
          onClick={onAddDate}
        />
      </NavBar>

        <ResultContainer>
           <LoadingContainer loading={loading}>
          {Object.keys(bookableItemsByDay).length ? (
            days.map(day => {
              const dayDate = moment(date).set('day', day);
              if (bookableItemsByDay && bookableItemsByDay[day]) {
                return (
                  <DayBlock
                    isDollar={isDollar}
                    day={day}
                    bookableItems={bookableItemsByDay[day]}
                    date={dayDate}
                  />
                );
              } else {
                return <p></p>;
              }
            })
          ) : (
            <div>{!loading && <Empty>Inga tider för valt datum</Empty>}</div>
          )}
          </LoadingContainer>
        </ResultContainer>
    </Container>
  );
};

export default ScheduleMonthView;
