import React from "react";
import styled from "styled-components";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 40px 80px 10px 80px;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  flex-basis: 100%;
  margin: 0;
  font-size: 24px;

`;

const PageHeader = ({ title, actionButtons }) => (
  <Row>
    <Title>{title}</Title>
    {actionButtons}
  </Row>
);

export default PageHeader;
