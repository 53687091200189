const masterConfig = {
  language: {
    id: "2",
    code: "sv-SE"
  },

  priceConfig: {
    id: "342",
    dynamic: true,
    basePrice: 800,
    limitIndex: 2,
    percentHigh: 50,
    percentLow: 50
  },

  baseConfig: {
    title: "Simpel konfiguration",
    id: "drgfg",
    maxEntries: 4,
    resolutionInterval: 12,
    resolutionType: "HOUR"
  },
  rules: [],
  schedules: [
    {
      id: "1",
      startHour: 8,
      endHour: 18,
      startMinute: 0,
      endMinute: 0,
      daysTag: "weekdays"
    },
    {
      id: "2",
      startHour: 8,
      endHour: 23,
      startMinute: 0,
      endMinute: 0,
      daysTag: "weekends"
    }
  ],
  periods: [
    {
      id: "a",
      title: "Öppettidsschema Normal",
      rules: [],
      schedules: ["1", "2"]
    }
  ],
  seasons: [
    {
      id: "b",
      color: "#1ea692",
      title: "Öppettidsschema",
      timePeriods: [{ from: "2019-01-01", to: "2019-12-30" }],
      rules: [],
      periods: ["a"]
    }
  ],
  years: [
    {
      year: 2019,
      seasons: ["b", "a", "c"],
      campaigns: [] //['skfjhshvv']
    }
  ],
  events: []
};
export default masterConfig;
