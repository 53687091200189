// import masterConfig from "data/master_config";
import generateId from "helpers/generateId";

import configs from "data/configs";

const masterConfig = configs[1];

let periods = masterConfig.periods;
let rules = masterConfig.rules;
let seasons = masterConfig.seasons;
let schedules = masterConfig.schedules;
let events = masterConfig.events;
let baseConfig = masterConfig.baseConfig;
let priceConfig = masterConfig.priceConfig;
let language = masterConfig.language;

const resolvers = {
  Query: {
    listPeriods: () =>
      periods.map(p => {
        return {
          ...p,
          schedules: p.schedules.map(sId =>
            schedules.find(schedule => schedule.id === sId)
          ),
          rules: p.rules.map(rule => rules.find(r => rule === r.id))
        };
      }),
    listRules: () => rules,
    listSeasons: () =>
      seasons.map(s => {
        return {
          ...s,
          // periods: [],
          rulePeriods: s.rulePeriods ? s.rulePeriods.map(rulePeriod => {
            return {
              ...rulePeriod,
              // rule: rules.find(rule => rule.id === rulePeriod.rule)

            }
          }) : [] ,

          periods: s.periods.map(pId => {
            const per = periods.find(period => period.id === pId);
            return {
              ...per,
              schedules: per.schedules.map(sId =>
                schedules.find(schedule => schedule.id === sId)
              )
            };
          }),

          rules: s.rules.length
            ? s.rules.map(rule => rules.find(r => rule === r.id))
            : []
        };
      }),
    listEvents: () =>
      events.map(e => {
        return {
          ...e,
          rules: e.rules.length
            ? e.rules.map(rule => rules.find(r => rule === r.id))
            : []
        };
      }),
    getPeriod: (a, { id }) => {
      return periods[0];
    },
    getSeason: (a, { id }) => {
      return seasons[0];
    },
    getBaseConfig: () => {
      return baseConfig;
    },
    getPriceConfig: () => {
      return priceConfig;
    },
    getLanguage: () => {
      return language;
    }
  },

  Mutation: {
    createSchedule: (arai, { input }, stack, schema) => {
      schedules.push(input);
    },
    updateSchedule: (arai, { input }, stack, schema) => {
      schedules = schedules.map(s => (s.id === input.id ? input : s));
      return input;
    },
    deleteSchedule: (arai, { input }, stack, schema) => {
      schedules = schedules.filter(s => s.id !== input.id);
      return input;
    },
    createPeriod: (arai, { input }, stack, schema) => {
      periods.push(input);
    },
    updatePeriod: (arai, { input }, stack, schema) => {
      const oldPeriod = periods.find(p => p.id === input.id);
      const newSchedules = input.schedules
        ? JSON.parse(input.schedules)
        : oldPeriod.schedules;

      const newPeriod = {
        ...input,
        schedules: newSchedules
      };
      periods = periods.map(p => (p.id === input.id ? newPeriod : p));

      // Hack update the schedule array
      schedules = schedules.map(s =>
        newSchedules.map(ns => ns.id).includes(s.id)
          ? newSchedules.find(ns => s.id === ns.id)
          : s
      );

      if (input.rules) {
        const idArray = input.rules ? JSON.parse(input.rules) : oldPeriod.rules;
        newPeriod.rules = idArray.map(rule => rules.find(r => rule === r.id));
      }

      return newPeriod;
    },
    deletePeriod: (arai, { input }, stack, schema) => {
      periods = periods.filter(p => p.id !== input.id);
      return input;
    },
    /*
    createRule: (arai, { input }, stack, schema) => {
      rules.push(input);
    },
    updateRule: (arai, { input }, stack, schema) => {
      rules = rules.map(p => (p.id === input.id ? input : p));
      return input;
    },
    deleteRule: (arai, { input }, stack, schema) => {
      rules = rules.filter(p => p.id !== input.id);
      return input;
    },
    */
    createSeason: (arai, { input }, stack, schema) => {
      const newSeason = {
        id: generateId(),
        ...input,

        periods: JSON.parse(input.periods),
        rules: JSON.parse(input.rules),
        timePeriods: JSON.parse(input.timePeriods)
      };

      seasons.unshift(newSeason);
      return newSeason;
    },
    updateSeason: (arai, { input }, stack, schema) => {
      const oldItem = seasons.find(item => item.id === input.id);
      const newItem = {
        ...oldItem,
        ...input
      };

      console.log("got new item: ", newItem);

      //if (input.periods) {

      /*
      const pArray = input.periods
        ? JSON.parse(input.periods)
        : oldItem.periods;
      console.log("the pArray: ", pArray);
      newItem.periods = pArray.map(period =>
        periods.find(p => period.id === p.id)
      );


      */
      /*
        const idArray = input.periods ? JSON.parse(input.rules) : oldItem.rules;
        newItem.periods = input.periods
          ? JSON.parse(input.periods)
          : oldItem.schedules;
          */
      // }
      if (input.timePeriods) {
        newItem.timePeriods = input.timePeriods
          ? JSON.parse(input.timePeriods)
          : oldItem.timePeriods;
      }

      if (input.rules) {
        newItem.rules = JSON.parse(input.rules).map(rule => rule.id);
        // const idArray = input.rules ? JSON.parse(input.rules) : oldItem.rules;
        // newItem.rules = idArray.map(rule => rules.find(r => rule === r.id));
        // newItem.rules = JSON.parse(input.rules);
      }

      if (input.periods) {
        newItem.periods = JSON.parse(input.periods).map(period => period.id);
      }

      
      /*
      if (input.rulePeriods) {
        newItem.rulePeriods = JSON.parse(input.rulePeriods).map(rulePeriod => {
          return {
            ...rulePeriod,
            rule: rulePeriod.rule.id
          }
        });
      }
      
    */

   if (input.rulePeriods) {
    newItem.rulePeriods = JSON.parse(input.rulePeriods);
  }

      // newItem.rulePeriods = "[]";
      seasons = seasons.map(item => (item.id === input.id ? newItem : item));

      const output = {
        ...input,
        periods: JSON.parse(input.periods),
        rules: JSON.parse(input.rules),
        timePeriods: JSON.parse(input.timePeriods),
        rulePeriods: JSON.parse(input.rulePeriods)
      };
      console.log("Output: ", output );
      return output;

      /*
      // Hack update the schedule array
      schedules = schedules.map(s =>
        newSchedules.map(ns => ns.id).includes(s.id)
          ? newSchedules.find(ns => s.id === ns.id)
          : s
      );
      */
      // return newItem;
      // return input;

      //  return input;

      /*
      return {
        ...newItem,
        // periods: [],

        periods: newItem.periods.map(pId => {
          const per = periods.find(period => period.id === pId);
          return {
            ...per,
            schedules: per.schedules.map(sId =>
              schedules.find(schedule => schedule.id === sId)
            )
          };
        }),

        rules: newItem.rules.length
          ? newItem.rules.map(rule => rules.find(r => rule === r.id))
          : []
      };
      */
    },
    deleteSeason: (arai, { input }, stack, schema) => {
      seasons = seasons.filter(p => p.id !== input.id);
      return input;
    },

    createEvent: (arai, { input }, stack, schema) => {
      events.push(input);
    },

    updateEvent: (arai, { input }, stack, schema) => {
      const oldItem = events.find(item => item.id === input.id);

      const newItem = {
        ...oldItem,

        schedules: JSON.parse(input.schedules),
        rules: JSON.parse(input.rules),
        timePeriods: JSON.parse(input.timePeriods)
      };

      if (newItem.rules) {
        newItem.rules = newItem.rules.map(rule => rule.id);
      }

      // delete newItem.__typename;

      /*
      console.log(oldItem,input);

      if (input.timePeriods) {
        newItem.timePeriods = input.timePeriods
          ? JSON.parse(input.timePeriods)
          : oldItem.timePeriods;
      }

    
      if (input.rules) {
        newItem.rules = JSON.parse(input.rules).map(rule => rule.id);
      }
 

      if (input.schedules) {
        newItem.schedules = JSON.parse(input.schedules);
      }
      */

      events = events.map(item => (item.id === input.id ? newItem : item));

      // return newItem;
      return {
        ...input,
        schedules: JSON.parse(input.schedules),
        rules: JSON.parse(input.rules),
        timePeriods: JSON.parse(input.timePeriods)
      };
      // return input;
    },
    deleteEvent: (arai, { input }, stack, schema) => {
      events = events.filter(p => p.id !== input.id);
      return input;
    },
    updateBaseConfig: (arai, { input }, stack, schema) => {
      baseConfig = input;
      return baseConfig;
    },
    updatePriceConfig: (arai, { input }, stack, schema) => {
      priceConfig = input;
      return priceConfig;
    },

    updateLanguage: (arai, { input }, stack, schema) => {
      language = input;
      console.log(language);
      return language;
    },

    createRule: (arai, { input }, stack, schema) => {
      const newRule = {
        id: generateId(),
        name: input.name,
        description: input.description,
        expressions: JSON.parse(input.expressions)
      };

      rules.unshift(newRule);
      return newRule;
    },

    updateRule: (arai, { input }, stack, schema) => {
      const newRule = {
        id: input.id,
        name: input.name,
        description: input.description,
        expressions: JSON.parse(input.expressions)
      };

      rules = rules.map(rule => {
        return rule.id !== input.id ? rule : newRule;
      });
      return newRule;
    },

    loadConfig: (arai, { input }, stack, schema) => {
      const config = JSON.parse(input.config);

      console.log(config);

      periods = config.periods;
      rules = config.rules;
      seasons = config.seasons;
      schedules = config.schedules;
      events = config.events;
      baseConfig = config.baseConfig;
      priceConfig = config.priceConfig;
      language = config.language;
      // return config;
    }
  }
};

export default resolvers;

/* 

onSaveTime={newTime => {
            setData({
              ...data,
              periods: data.periods.map(p => {
                if (p.id === period.id) {
                  return {
                    ...p,
                    schedules: period.schedules.map(schedule => {
                      let times = [];
                      if(!schedule.times.find(t => t.id === newTime.id)) {
                        times = [
                          newTime,
                          ...schedule.times
                        ]
                      } else {
                        times = schedule.times.map(time => {
                          if (time.id === newTime.id) {
                            return newTime;
                          }
                          return time;
                        });

                      }
                      
                      return {
                        ...schedule,
                        times
                      };
                    })
                  };
                } else {
                  return p;
                }
              })
            });
          }}
          onDeleteTime={deleteTime => {
            setData({
              ...data,
              periods: data.periods.map(p => {
                if (p.id === period.id) {
                  return {
                    ...p,
                    schedules: period.schedules.map(schedule => {
                      return {
                        ...schedule,
                        times: schedule.times.filter(time => {
                          return time.id !== deleteTime.id;
                        })
                      };
                    })
                  };
                } else {
                  return p;
                }
              })
            });
          }}
          onDeleteSchedule={deletePeriod => {
            setData({
              ...data,
              periods: data.periods.filter(p => p.id !== deletePeriod.id)
            });
          }}
           */
