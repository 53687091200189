import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { theme } from "@golfersweden/pegora-ui";
import Popup from "./Popup";
import CalendarSlot from "./CalendarSlot";
import OccupancyBar from "./OccupancyBar";
import MonthBlockPopup from "./MonthBlockPopup";

const PriceLabel = styled.p`
  font-family: Space Grotesk;
  font-size: 26px;
  color: ${theme.pegora_navy};
`;

// import DayBlockPopup from "./DayBlockPopup";

/*
const PriceLabel = styled.p`
font-family: Space Grotesk;
font-size: 26px;
color: ${theme.pegora_navy};
`;
*/

const TimeLabel = styled.p`
  font-size: 16px;
  color: ${theme.pegora_navy};
`;

const MonthBlock = ({ month, bookableItems, date, isDollar }) => {
  const [showDetails, setShowDetails] = useState(false);

  const todaysMoment = moment(date).set("month", month);
  // const bookableItems = data.startTimeSchedule.bookableItems;
  /*
  let bookableItems = [];
  if (data && data.startTimeSchedule) {
    bookableItems = data.startTimeSchedule.bookableItems.filter(booking => {
      return (
        moment(booking.startTime).format("MM-DD") ===
        todaysMoment.format("MM-DD")
      );
    });
  }
  */

  const sum = bookableItems
    .map(b => b.price)
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

  const averagePrice = parseInt(sum / bookableItems.length, 10);

  const monthString =
    month.substr(0, 1).toUpperCase() + month.substr(1).toLowerCase();
  return (
    <Popup
      visible={showDetails}
      onClose={() => {
        setShowDetails(false);
      }}
      title={moment(date).format("DDDD")}
      content={
        <MonthBlockPopup
          date={todaysMoment}
          averagePrice={averagePrice}
          isDollar={isDollar}
          monthString={monthString}
          month={month}
          bookableItems={bookableItems}
        />
      }
    >
      <CalendarSlot bookable onClick={() => setShowDetails(!showDetails)}>
        <TimeLabel>{monthString}</TimeLabel>
        <div style={{ marginBottom: 12 }}>
          <OccupancyBar bookableItems={bookableItems} />
        </div>

        {!isDollar ? (
          <PriceLabel>
            {averagePrice} <span style={{ fontSize: 20 }}>kr</span>
          </PriceLabel>
        ) : (
          <PriceLabel>
            <span style={{ fontSize: 20 }}>$</span>
            {averagePrice}
          </PriceLabel>
        )}
      </CalendarSlot>
    </Popup>
  );
};

export default MonthBlock;
