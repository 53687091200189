import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import GraphQLProvider from "graphql/GraphQLProvider";
import styled from "styled-components";

import moment from "moment";
import "moment/locale/sv";
import { hot } from "react-hot-loader";
import "@golfersweden/pegora-ui/dist/bundle.css";
import { theme } from '@golfersweden/pegora-ui';

import SettingsPage from "pages/SettingsPage";
import WizardPage from "pages/WizardPage";
import OverviewPage from "pages/OverviewPage";
import RulesPage from "pages/RulesPage";
import PeriodsPage from "pages/PeriodsPage";
import EventsPage from "pages/EventsPage";


const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
  height: 100%;
  justify-content: stretch;
  background-color: ${theme.light_sand};
`;

const Content = styled.div`
  flex-grow: 1;
  height: 100%;
`;


function App() {
  moment.locale("sv");
  return (
    <GraphQLProvider>
      <Router>
        <Switch>
          <Container>
            <Content>
              <Route path="/wizard" component={WizardPage} />
              <Route path="/" exact component={OverviewPage} />
              <Route path="/settings" exact component={SettingsPage} />
              <Route path="/overview" exact component={OverviewPage} />
              <Route path="/rules" exact component={RulesPage} />
              <Route path="/events" exact component={EventsPage} />
              <Route path="/periods" exact component={PeriodsPage} />
            </Content>
          </Container>
        </Switch>
      </Router>
    </GraphQLProvider>
  );
}

export default hot(module)(App);
