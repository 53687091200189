const configs = {
  1: {
    language: {
      id: "2",
      code: "sv-SE"
    },

    priceConfig: {
      id: "342",
      dynamic: true,
      basePrice: 800,
      limitIndex: 20,
      percentHigh: 50,
      percentLow: 50
    },

    baseConfig: {
      title: "Simpel konfiguration",
      id: "drgfg",
      maxEntries: 4,
      resolutionInterval: 7,
      resolutionType: "HOUR"
    },
    rules: [
      {
        id: "hcp_25",
        name: "Endast spelare med handicap under 25 får boka",
        description: "-",
        expressions: [
          {
            inverted: true,
            ruleType: "handicap",
            operator: "under",
            value: "25"
          }
        ]
      },
    ],
    schedules: [
      {
        id: "1",
        startHour: 8,
        endHour: 18,
        startMinute: 0,
        endMinute: 0,
        daysTag: "all-days"
      },
      /*
      {
        id: "2",
        startHour: 8,
        endHour: 23,
        startMinute: 0,
        endMinute: 0,
        daysTag: "weekends"
      }
      */
    ],
    periods: [
      {
        id: "a",
        title: "Öppettidsschema Normal",
        rules: [],
        schedules: ["1"]
      }
    ],
    seasons: [
      {
        id: "b",
        color: "#1ea692",
        title: "Öppettidsschema",
        description: "-",
        timePeriods: [{ from: "2019-01-01", to: "2019-12-30" }],
        rules: ["hcp_25"],
        periods: ["a"],
        rulePeriods: [
          {
            id: '1',
            rule: "hcp_25",
            schedules: [
              {
                id: "4",
                startHour: 7,
                endHour: 19,
                startMinute: 0,
                endMinute: 0,
                daysTag: "saturdays",
                weekOfMonths: "[1,2,3,4]"
              },
            ]
          }
        ]
      }
    ],
    years: [
      {
        year: 2019,
        seasons: ["b", "a", "c"],
        campaigns: [] //['skfjhshvv']
      }
    ],
    events: []
  },
  2: {
    language: {
      id: "2",
      code: "sv-SE"
    },

    priceConfig: {
      id: "23",
      dynamic: true,
      basePrice: 800,
      limitIndex: 20,
      percentHigh: 50,
      percentLow: 50
    },

    baseConfig: {
      title: "Testbana 1",
      id: "drgfg",
      maxEntries: 4,
      resolutionInterval: 7,
      resolutionType: "HOUR"
    },
    rules: [
      {
        id: "only_members",
        name: "Endast medlemmar får boka",
        description: "-",
        expressions: [
          {
            inverted: true,
            ruleType: "membership",
            value: "Medlemsskap"
          }
        ]
      },
      {
        id: "hcp_25",
        name: "Endast spelare med handicap under 25 får boka",
        description: "-",
        expressions: [
          {
            inverted: true,
            ruleType: "handicap",
            operator: "under",
            value: "25"
          }
        ]
      },
      {
        id: "senior",
        name: "Seniorgolf",
        description: "Alla som har ålder över 60",
        expressions: [
          {
            inverted: true,
            ruleType: "age",
            operator: "over",
            value: "60"
          }
        ]
      }
    ],
    schedules: [
      {
        id: "1",
        startHour: 7,
        endHour: 19,
        startMinute: 0,
        endMinute: 0,
        daysTag: "all-days"
      },
      {
        id: "2",
        startHour: 6,
        endHour: 19,
        startMinute: 0,
        endMinute: 0,
        daysTag: "weekdays"
      },
      {
        id: "3",
        startHour: 6,
        endHour: 21,
        startMinute: 0,
        endMinute: 0,
        daysTag: "weekends"
      },
      {
        id: "4",
        startHour: 8,
        endHour: 17,
        startMinute: 0,
        endMinute: 0,
        daysTag: "all-days"
      }
    ],
    periods: [
      {
        id: "a",
        title: "Standard",
        rules: [],
        schedules: ["1"]
      },
      {
        id: "b",
        title: "Högsäsong",
        rules: [],
        schedules: ["2", "3"]
      },
      
      {
        id: "c",
        title: "Lågsäsong",
        rules: [],
        schedules: ["4"]
      }
      
    ],
    seasons: [
      {
        id: "a",
        color: "#1ea692",
        title: "Standard",
        description: "-",
        timePeriods: [{ from: "2019-04-01", to: "2019-06-01" }],
        rules: ["only_members", "hcp_25", "senior"],
        rulePeriods: [
          {
            id: '1',
            rule: "only_members",
            schedules: [
              {
                id: "4",
                startHour: 7,
                endHour: 19,
                startMinute: 0,
                endMinute: 0,
                daysTag: "saturdays",
                weekOfMonths: "[1,3]"
              },
            ]
          },
          {
            id: '2',
            rule: "hcp_25",
            schedules: [
              {
                id: "5",
                startHour: 6,
                endHour: 22,
                startMinute: 0,
                endMinute: 0,
                daysTag: "all-days",
              },
            ]
          },
          {
            id: '3',
            rule: "senior",
            schedules: [
              {
                id: "6",
                startHour: 8,
                endHour: 12,
                startMinute: 0,
                endMinute: 0,
                daysTag: "tuesdays",
              },
            ]
          }
        ],
        periods: ["a"]
      },
      {
        id: "b",
        color: "#1ea692",
        description: "-",
        title: "Högsäsong",
        timePeriods: [{ from: "2019-06-02", to: "2019-09-01" }],
        rules: ["only_members", "hcp_25", "senior"],
        rulePeriods: [
          {
            id: '3',
            rule: "senior",
            schedules: [
              {
                id: "6",
                startHour: 8,
                endHour: 12,
                startMinute: 0,
                endMinute: 0,
                daysTag: "tuesdays",
              },
            ]
          },
          {
            id: '2',
            rule: "hcp_25",
            schedules: [
              {
                id: "5",
                startHour: 6,
                endHour: 22,
                startMinute: 0,
                endMinute: 0,
                daysTag: "all-days",
              },
            ]
          },
          {
            id: '1',
            rule: "only_members",
            schedules: [
              {
                id: "4",
                startHour: 7,
                endHour: 19,
                startMinute: 0,
                endMinute: 0,
                daysTag: "saturdays",
                weekOfMonths: "[1,3]"
              },
            ]
          },
        ],
        periods: ["b"]
      },
      
      {
        id: "c",
        color: "#1ea692",
        title: "Lågsäsong",
        description: "-",
        timePeriods: [{ from: "2019-09-02", to: "2019-11-01" }],
        rules: [ "hcp_25"],
        periods: ["c"],
        rulePeriods: [
          {
            id: '5',
            rule: "hcp_25",
            schedules: [
              {
                id: "5",
                startHour: 6,
                endHour: 22,
                startMinute: 0,
                endMinute: 0,
                daysTag: "all-days",
              },
            ]
          },
          {
            id: '3',
            rule: "senior",
            schedules: [
              {
                id: "6",
                startHour: 8,
                endHour: 12,
                startMinute: 0,
                endMinute: 0,
                daysTag: "tuesdays",
              },
            ]
          }

        ]
      }
      
    ],
    years: [
      {
        year: 2019,
        seasons: ["a", "b"],
        campaigns: [] //['skfjhshvv']
      }
    ],
    events: []
  },
  3: {
    language: {
      id: "3",
      code: "sv-SE"
    },

    priceConfig: {
      id: "23",
      dynamic: true,
      basePrice: 800,
      limitIndex: 20,
      percentHigh: 50,
      percentLow: 50
    },

    baseConfig: {
      title: "Testbana 2",
      id: "drgfg",
      maxEntries: 4,
      resolutionInterval: 7,
      resolutionType: "HOUR"
    },
    rules: [
      {
        id: "only_members",
        name: "Endast medlemmar får boka",
        description: "-",
        expressions: [
          {
            inverted: true,
            ruleType: "membership",
            value: "Medlemsskap"
          }
        ]
      },
      {
        id: "under_20",
        name: "Juniortävling",
        description: "-",
        expressions: [
          {
            inverted: true,
            ruleType: "age",
            operator: "under",
            value: "20"
          }
        ]
      }
    ],
    schedules: [
      {
        id: "1",
        startHour: 7,
        endHour: 19,
        startMinute: 0,
        endMinute: 0,
        daysTag: "all-days"
      },
      {
        id: "2",
        startHour: 6,
        endHour: 21,
        startMinute: 0,
        endMinute: 0,
        daysTag: "weekends"
      }
    ],
    periods: [
      {
        id: "a",
        title: "Standard",
        rules: [],
        schedules: ["1"]
      },
      {
        id: "b",
        title: "Högsäsong",
        rules: [],
        schedules: ["2"]
      }
    ],
    seasons: [
      {
        id: "a",
        color: "#1ea692",
        title: "Standard",
        description: "-",
        timePeriods: [{ from: "2019-04-01", to: "2019-11-01" }],
        rules: ["only_members", "under_20"],
        rulePeriods: [
          {
            id: '3',
            rule: "only_members",
            schedules: [
              {
                id: "6",
                startHour: 10,
                endHour: 14,
                startMinute: 0,
                endMinute: 0,
                daysTag: "saturdays",
                weekOfMonths: "[1,3]"
              },
            ]
          },
          {
            id: '4',
            rule: "under_20",
            schedules: [
              {
                id: "7",
                startHour: 8,
                endHour: 12,
                startMinute: 0,
                endMinute: 0,
                daysTag: "saturdays",
                weekOfMonths: "[1,2,3]"
              },
            ]
          }
        ],
        periods: ["a"]
      },
      {
        id: "b",
        color: "#1ea692",
        title: "Högsäsong",
        description: "-",
        timePeriods: [{ from: "2019-06-01", to: "2019-09-01" }],
        rules: [],
        rulePeriods: [
          {
            id: '3',
            rule: "only_members",
            schedules: [
              {
                id: "6",
                startHour: 10,
                endHour: 14,
                startMinute: 0,
                endMinute: 0,
                daysTag: "saturdays",
                weekOfMonths: "[1,3]"
              },
            ]
          },
          {
            id: '4',
            rule: "under_20",
            schedules: [
              {
                id: "7",
                startHour: 8,
                endHour: 12,
                startMinute: 0,
                endMinute: 0,
                daysTag: "saturdays",
                weekOfMonths: "[1,2,3]"
              },
            ]
          }
        ],
        periods: ["b"]
      }
    ],
    years: [
      {
        year: 2019,
        seasons: ["a", "b"],
        campaigns: [] //['skfjhshvv']
      }
    ],
    events: []
  }
};
export default configs;
