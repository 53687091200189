import week_days from "data/week_days";
import week_enum from "data/week_enum";
import _ from "lodash";
import moment from "moment";

const englishDays = {
  måndag: "monday",
  tisdag: "tuesday",
  onsdag: "wednesday",
  torsdag: "thursday",
  fredag: "friday",
  lördag: "saturday",
  söndag: "sunday"
};
const parseWeekOfMonth = (m) => {
  return moment(m).week() - moment(m).startOf('month').week() + 1;
}
/*
const parseWeekOfMonth = (input ) => {
  const firstDayOfMonth = input.clone().startOf('month');
  const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');

  const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');

  return Math.ceil((input.date() + offset) / 7);
}
*/
export const parseSchedule = schedule => {
  const newSchedule = JSON.parse(JSON.stringify(schedule));

  if (schedule.daysTag) {
    newSchedule.weekdays = week_days[newSchedule.daysTag].dayArray.map(
      d => week_enum[d]
    );
    delete newSchedule.daysTag;
    delete newSchedule.id;
    
    if(schedule.weekOfMonths) {
      newSchedule.weekOfMonths = JSON.parse(schedule.weekOfMonths)
    } else {
      delete newSchedule.weeksOweekOfMonthsfMonth;
    }
  }

  return _.omit(newSchedule, "__typename");
};

export const getActiveRulesForDay = (date, configuration) => {
  const day = englishDays[date.format('dddd')];
  const weekOfMonth = parseWeekOfMonth(date._i);

  console.log("wtf is dis: ", day, date, date.format());

  const rules = [];
  configuration.listSeasons.forEach(season => {
    const timePeriod = season.timePeriods[0];
    season.periods.forEach(period => {
        season.rules.forEach(rule => {
          console.log(date.format(), timePeriod.from, timePeriod.to);
          if (date.isBetween(moment(timePeriod.from), moment(timePeriod.to))) {            
            const rulePeriods = season.rulePeriods ? season.rulePeriods.filter(rulePeriod => {
              return rulePeriod.rule === rule.id;
            }) : [];

            const parsedSchedule = parseSchedule(rulePeriods[0].schedules[0]);
              if(parsedSchedule.weekdays.includes(day)) {
                if(parsedSchedule.weekOfMonths && parsedSchedule.weekOfMonths.length) {
                  console.log("Has week of months array: ", rule.name);
                  if(parsedSchedule.weekOfMonths.includes(weekOfMonth)) {
                    rules.push(rule);
                  }
                } else {
                  rules.push(rule);
                }
              }
              }
        });
    });
  });
  return rules;
};

export const getActiveRulesForMonth = (from, to, configuration) => {
  console.log("from in getRulesInTimePeriod: ", from);
  const rules = [];
  configuration.listSeasons.forEach(season => {
    const timePeriod = season.timePeriods[0];
    season.periods.forEach(period => {
        season.rules.forEach(rule => {
          console.log(from.format(), timePeriod.from, timePeriod.to);
          if (from.isBetween(moment(timePeriod.from), moment(timePeriod.to))) {
            rules.push(rule);
          }
        });
    });
  });
  return rules;
};
export const getRulesInTimePeriod = (from, to, configuration) => {
  console.log("from in getRulesInTimePeriod: ", from);

  const rules = [];
  const day = englishDays[moment(from._i).format('dddd')];
  // console.log("Day: ", day);
  const hour = parseInt(from.format('H'));
  const weekOfMonth = parseWeekOfMonth(from._i);

  console.log('Week of month: ', weekOfMonth);

  configuration.listSeasons.forEach(season => {
    const timePeriod = season.timePeriods[0];
    // const momentFrom  = moment(timePeriod.from);


    // console.log(day, hour, weekOfMonth);

    season.periods.forEach(period => {
      // console.log(period);
        season.rules.forEach(rule => {
          //console.log(rule);
          if (from.isBetween(moment(timePeriod.from), moment(timePeriod.to))) {
            const rulePeriods = season.rulePeriods ? season.rulePeriods.filter(rulePeriod => {
              return rulePeriod.rule === rule.id;
            }) : [];

            const parsedSchedule = parseSchedule(rulePeriods[0].schedules[0]);
            //console.log("parsedSchedule", parsedSchedule, rule.name);
            // console.log(parsedSchedule);
            // console.log(parsedSchedule.startHour, hour, parsedSchedule.endHour);

            if(parsedSchedule.startHour <= hour && parsedSchedule.endHour >= hour ) {
              //console.log("startHour endHour is ok", rule.name);
              //console.log(parsedSchedule.weekdays, day);
              if(parsedSchedule.weekdays.includes(day)) {
                //console.log("includes day", rule.name);
                if(parsedSchedule.weekOfMonths && parsedSchedule.weekOfMonths.length) {
                  console.log("Has week of months array: ", rule.name);
                  if(parsedSchedule.weekOfMonths.includes(weekOfMonth)) {
                    rules.push(rule);
                  }
                } else {
                  rules.push(rule);
                }
              }
            }
          }
        });
    });
    /*
    if (from.isBetween(moment(timePeriod.from), moment(timePeriod.to))) {
      season.rules.forEach(rule => {
        rules.push(rule);
      });
    }
    */
  });
  return rules;
};

export const getSchedulesInTimePeriod = (from, to, configuration) => {

  const seasons = configuration.listSeasons
    .filter(season => {
      const timePeriod = season.timePeriods[0];
      if (!timePeriod) {
        return false;
      }
      return from.isBetween(moment(timePeriod.from), moment(timePeriod.to));
    })
    .map(season => {
      // const timePeriod = season.timePeriods[0];
      return {
        ...season,
        /*
        periods: season.periods.filter(period => {
          return (
            period.schedules.filter(schedule => {
              const scheduleFrom = moment(timePeriod.from).set({
                hour: schedule.fromHour,
                minute: schedule.fromMinute
              });
              const scheduleTo = moment(timePeriod.from).set({
                hour: schedule.endHour,
                minute: schedule.endMinute
              });
              const isBetween = from.isBetween(scheduleFrom, scheduleTo);
              return isBetween;
            }).length > 0
          );
        })
        */
      };
    });

  const events = configuration.listEvents
    .filter(event => {
      const timePeriod = event.timePeriods[0];
      if (!timePeriod) {
        return false;
      }
      return from.isBetween(moment(timePeriod.from), moment(timePeriod.to));
    })
    .map(event => {
      const timePeriod = event.timePeriods[0];
      const schedules = event.schedules.filter(schedule => {
        const scheduleFrom = moment(timePeriod.from).set({
          hour: schedule.fromHour,
          minute: schedule.fromMinute
        });
        const scheduleTo = moment(timePeriod.from).set({
          hour: schedule.endHour,
          minute: schedule.endMinute
        });
        const isBetween = from.isBetween(scheduleFrom, scheduleTo);
        return isBetween;
      });

      return {
        ...event,
        schedules
      };
    });

  return {
    seasons,
    events
  };
};

export const getSeasonsInTimePeriod = (from, to, configuration) => {};
