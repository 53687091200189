import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { theme } from "@golfersweden/pegora-ui";
import TimeBlockPopup from "./TimeBlockPopup";
import Popup from "./Popup";
import CalendarSlot from './CalendarSlot';
const Dot = styled.div`
  border-radius: 7px;
  height: 14px;
  width: 14px;
  margin-right: 1px;
  border: 1px solid ${theme.pegora_coral};
  background-color: ${props => (props.filled ? theme.pegora_coral : "none")};
`;

const PriceLabel = styled.p`
font-family: Space Grotesk;
font-size: 26px;
color: ${theme.pegora_navy};
`;

const TimeLabel = styled.p`
font-size: 16px;
color: ${theme.pegora_navy};
`;

const DotContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TimeBlock = ({ item, profile, isDollar }) => {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <Popup
      visible={showDetails}
      onClose={() => {
        setShowDetails(false);
      }}
      content={<TimeBlockPopup item={item} profile={profile} isDollar={isDollar} />}
    >
      <CalendarSlot
        // isBookable={canBook()}
        bookable={!item.rules.length}
        onClick={() => setShowDetails(!showDetails)}
      >
        <TimeLabel>{moment(item.startTime).format("HH:mm")}</TimeLabel>
        <DotContainer>
          {[...Array(item.maxEntries)].map((e, i) => {
            return <Dot key={i} filled={i < item.entries} />;
          })}
        </DotContainer>

        {!isDollar ? (
            <PriceLabel>{parseInt(item.price, 10).toString()} <span style={{ fontSize: 20 }}>kr</span></PriceLabel>
          ) : (
            <PriceLabel><span style={{ fontSize: 20 }}>$</span>{parseInt(item.price, 10).toString()}</PriceLabel>
          )}
      </CalendarSlot>
    </Popup>
  );
};

export default TimeBlock;
