import React from "react"
import {
  Button,
  theme
} from "@golfersweden/pegora-ui";
import styled from "styled-components";

const Container = styled.div`
  border-bottom: 1px solid ${theme.clear_blue};
  display: flex;
`;

const ValueLabel = styled.p`
  font-size: 18px;
  margin: 0;
  color: white;
  font-weight: bold;
  line-height: 33px;
  padding: 0 6px;
  white-space: nowrap;
`;

const ControlButton = styled(Button)`
  color: ${theme.clear_blue};
  margin-bottom: 0;
`;

const Prefix = styled.p`
  font-size: 18px;
  margin: 0;
  color: white;
  line-height: 33px;
  margin-right: 10px;
  opacity: 0.8;
  white-space: nowrap;
`;

const CustomNumberPicker = ({
  prefix,
  suffix,
  valueArray,
  value,
  onChange
}) => {
  console.log(value);

  const onAdd = () => {
    console.log(value);
    triggerChange(parseInt(value) + 1);
    // setVal(val+1);
  };
  const onSub = () => {
    console.log(value);
    triggerChange(parseInt(value) - 1);
  };
  const triggerChange = changedValue => {
    console.log(changedValue);
    // Should provide an event to pass value to Form.
    if (onChange) {
      onChange(changedValue);
    } else {
      console.log("wtf");
    }
  };

  return (
    <div>
      <Container>
        {prefix && <Prefix>{prefix}</Prefix>}
        <ControlButton type="link" block icon="minus" onClick={onSub} />
        <ValueLabel>
          {value}
          {suffix}
        </ValueLabel>
        <ControlButton type="link" block icon="plus" onClick={onAdd} />
      </Container>
    </div>
  );
};

export default CustomNumberPicker;