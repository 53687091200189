import React, { useState } from "react";
import styled from "styled-components";
import { theme, Button, TimePicker } from "@golfersweden/pegora-ui";
// import { useQuery } from '@apollo/react-hooks';
import moment from "moment";

// import { GET_PERIODS } from 'graphql/Queries';
const Title = styled.h1`
  text-align: center;
  font-size: 40px;
  color: white;
`;

const Paragraph = styled.p`
  text-align: center;
  font-size: 18px;
  color: white;
`;

const Page = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: ${theme.pegora_navy};
  height: 100%;
`;

const CellContainer = styled.div`
  text-align: center;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  display: inline-block;
`;
const Cell = styled.div`
  width: 50%;
  display: inline-block;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
`;

const SubTitle = styled.h5`
  color: white;
  margin-top: 40px;
  font-size: 18px;
`;

const CenterContainer = styled.div`
  text-align: center;
`;

const format = "HH:mm";

/*
const PageSteps = ({ page }) => {
  return (
    <Steps size="small" current={page}>
      <Step title="Finished" />
      <Step title="In Progress" />
      <Step title="Waiting" />
    </Steps>
  );
};
*/
const WizardPage = () => {
  const [page, setPage] = useState(0);

  console.log(page, page.length);

  // const { loading, data } = useQuery(GET_PERIODS);

  const nextPage = () => {
    setPage(page + 1);
  };
  const finish = () => {
    window.location.replace("/overview");
  };

  const pages = [
    <React.Fragment>
      <Paragraph>Välkommen till Pegora FlexSheet!</Paragraph>
      <Paragraph>
        Den här guiden hjälper dig att sätta upp ditt första FlexSheet!
      </Paragraph>
    </React.Fragment>,
    <React.Fragment>
      <Paragraph>Hur många spelare kan boka samma tid?</Paragraph>
      <CenterContainer>
        <CellContainer>
          <Cell>
            <Button size="large">En spelare</Button>
          </Cell>
          <Cell>
            <Button size="large">Två spelare</Button>
          </Cell>
          <Cell>
            <Button size="large">Tre spelare</Button>
          </Cell>
          <Cell>
            <Button size="large">Fyra spelare</Button>
          </Cell>
        </CellContainer>
      </CenterContainer>
    </React.Fragment>,
    <React.Fragment>
      <Paragraph>Hur ofta vill du generera tider i ditt schema?</Paragraph>
      <CenterContainer>
        <CellContainer>
          <Cell>
            <Button size="large">Var 10:e minut</Button>
          </Cell>
          <Cell>
            <Button size="large">Var 15:e minut</Button>
          </Cell>
          <Cell>
            <Button size="large">Var 20:e minut</Button>
          </Cell>
          <Cell>
            <Button size="large">Var 30:e minut</Button>
          </Cell>
        </CellContainer>
      </CenterContainer>
    </React.Fragment>,
    <React.Fragment>
      <Paragraph>
        Hur ser ditt schema ut? (Du kan ändra det här senare!
      </Paragraph>
      <CenterContainer>
        <Button size="large">Samma tider alla dagar</Button>
        <br />
        <br />
        <Button size="large">Olika tider på vardagar och helger</Button>
        <br />
        <br />
        <Button size="large">
          Olika tider på vardagar, lördagar och söndagar
        </Button>
        <br /> <br />
        <Button size="large">Välj eget...</Button>
        <br />
        <br />
      </CenterContainer>
    </React.Fragment>,
    <React.Fragment>
      <Paragraph>Ställ in dina tider</Paragraph>
      <div style={{ textAlign: "center", color: "white" }}>
        <SubTitle>Vardagar</SubTitle>
        <div>
          Öppet mellan{" "}
          <TimePicker defaultValue={moment("08:00", format)} format={format} />{" "}
          och{" "}
          <TimePicker defaultValue={moment("18:30", format)} format={format} />
        </div>
        <SubTitle>Helger</SubTitle>
        <div>
          Öppet mellan{" "}
          <TimePicker defaultValue={moment("12:00", format)} format={format} />{" "}
          och{" "}
          <TimePicker defaultValue={moment("18:30", format)} format={format} />
        </div>
        <br />
      </div>
    </React.Fragment>
  ];

  return (
    <Page>
      {/*loading ? <p>Loading</p> : (
        <p style={{color: 'white'}}>Got data: ", {JSON.stringify(data)} </p>
      ) */}
      <Title>FlexSheet</Title>
      {/* <PageSteps page={page} />*/}
      {pages[page]}
      {page === 0 && (
        <CenterContainer>
          <Button size="large" type="primary" onClick={() => nextPage()}>
            Starta
          </Button>
        </CenterContainer>
      )}
      )}
      {page > 0 && page < pages.length -1 && (
        <CenterContainer>
          <Button size="large" type="primary" onClick={() => nextPage()}>
            Nästa
          </Button>
        </CenterContainer>
      )}
      {page === pages.length -1 && (
        <CenterContainer>
          <Button size="large" type="primary" onClick={() => finish()}>
            Spara
          </Button>
        </CenterContainer>
      )}
    </Page>
  );
};

export default WizardPage;
