import React, { useState } from "react";
import styled from "styled-components";
import { Button, Menu, Dropdown, Icon, theme } from "@golfersweden/pegora-ui";
import ScheduleSeasonForm from "components/ScheduleEditor/forms/EditorSeasonForm";
import ScheduleTimeBlock from "components/ScheduleTimeBlock2";
import ScheduleTimeForm from "components/ScheduleEditor/forms/ScheduleTimeForm";
import CustomLabel from "components/ScheduleEditor/CustomLabel";
import generateId from 'helpers/generateId';
import _ from "lodash";
// import { GET_PERIODS } from "graphql/Queries";
import {
  //   CREATE_SEASON,
  UPDATE_PERIOD
} from "graphql/Mutations";
import { useMutation } from "@apollo/react-hooks";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

/*
const EditButton = styled(Button)`
  color: yellow;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
`;
*/

const RuleContainer = styled.div`
  border-bottom: 2px solid rgb(82, 103, 204);
  padding-bottom: 10px;
  margin-bottom: 10px;
  &:last-child {
    border-bottom: none;
    padding-bottom: none;
    margin-bottom: none;
  }
  /*
  background-color: #ddd;
  color: black;
 
  display: flex;
  align-items: center;
  padding: 1px;
  margin: 1px;
  justify-content: space-between;
   */
`;

const Ball = styled.div``;

const makeNewRulePeriod = rule => {
  return {
    id: generateId(),
    rule: rule.id,
    schedules: [
      {
        id: generateId(),
        startHour: 7,
        endHour: 19,
        startMinute: 0,
        endMinute: 0,
        daysTag: "all-days",
      },
    ]
  }
}

const parseRulePeriods = season => {
  return season.rulePeriods
      .map(r => {
        const newR = _.omit(r, ["__typename"]);
        return {
          ...newR,
          schedules: r.schedules.map(s => {
            const newS = _.omit(s, ["__typename"]);
            if (newS.weekOfMonths === null) {
              delete newS.weekOfMonths;
              // newS.weekOfMonths = JSON.stringify([1,2,3,4,5]);
            }

            return newS;
          })
        };
      });
}
const SeasonItem = ({
  season,
  showRules,
  showTimes,
  updateSeason,
  createSeason,
  rules
}) => {
  const [addNewPeriodSchedule, setAddNewPeriodSchedule] = useState(false);

  console.log(season);

  const [updatePeriod] = useMutation(UPDATE_PERIOD, {});
  const saveScheduleToPeriod = ({ period, schedule }) => {
    const variables = {
      input: {
        id: period.id,
        title: period.title,
        schedules: JSON.stringify([schedule.input, ...period.schedules]),
        rules: JSON.stringify(period.rules)
      }
    };

    updatePeriod({ variables });
    setAddNewPeriodSchedule(false);
  };

  const deleteScheduleFromPeriod = ({ period, schedule }) => {
    const variables = {
      input: {
        id: period.id,
        title: period.title,
        schedules: JSON.stringify(
          period.schedules.filter(s => s.id !== schedule.id)
        ),
        rules: JSON.stringify(period.rules)
      }
    };

    updatePeriod({ variables });
    setAddNewPeriodSchedule(false);
  };

  const addToSeason = ({ rule, season }) => {
    const newSeason = JSON.parse(JSON.stringify(season));
    const newRule = { ...rule };
    delete newRule.__typename;
    delete newSeason.__typename;

    console.log("newRUle: ", newRule);
    const input = {
      input: {
        ...newSeason,
        timePeriods: JSON.stringify(
          season.timePeriods.map(t => _.omit(t, ["__typename"]))
        ),
        rulePeriods: JSON.stringify([...parseRulePeriods(season), makeNewRulePeriod(rule)]),
        periods: JSON.stringify(season.periods),
        rules: JSON.stringify([...season.rules, newRule])
      }
    };
    updateSeason({ variables: input });
  };

  const updateRulePeriod = ({rulePeriod, schedule, season}) => {

    const newRulePeriod = {
      ...rulePeriod,
      schedules: [schedule]
    }
    console.log("UPDATE RULE PERIOD", rulePeriod, season);

    const newSeason = JSON.parse(JSON.stringify(season));
    delete newSeason.__typename;
    const input = {
      input: {
        ...newSeason,
        timePeriods: JSON.stringify(
          season.timePeriods.map(t => _.omit(t, ["__typename"]))
        ),
        rulePeriods: JSON.stringify(parseRulePeriods(season).map(rp => rp.id === rulePeriod.id ? newRulePeriod : rp)),
        periods: JSON.stringify(season.periods),
        rules: JSON.stringify(season.rules)
      }
    };

    console.log(input);
    updateSeason({ variables: input });
  }

  const removeFromSeason = ({ rule, season }) => {
    const newSeason = JSON.parse(JSON.stringify(season));
    delete newSeason.__typename;

    const input = {
      input: {
        ...newSeason,
        timePeriods: JSON.stringify(
          season.timePeriods.map(t => _.omit(t, ["__typename"]))
        ),
        periods: JSON.stringify(season.periods),
        rules: JSON.stringify(season.rules.filter(r => r.id !== rule.id)),
        rulePeriods: JSON.stringify(
          season.rulePeriods
            .map(r => {
              const newR = _.omit(r, ["__typename"]);
              return {
                ...newR,
                schedules: r.schedules.map(s => {
                  const newS = _.omit(s, ["__typename"]);
                  if (newS.weekOfMonths === null) {
                    delete newS.weekOfMonths;
                  }

                  return newS;
                })
              };
            })
            .filter(rP => rP.rule.id !== rule.id)
        )
      }
    };

    updateSeason({ variables: input });
  };

  const menu = () => (
    <Menu>
      {rules &&
        rules.map(rule => (
          <Menu.Item
            onClick={() => {
              addToSeason({ rule, season });
            }}
          >
            {rule.name}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <div>
      <Ball color={season && season.color ? season.color : "#666"} />
      <ScheduleSeasonForm season={season} />
      <Row
            style={{
              marginTop: 20,
              width: "100%",
              justifyContent: "space-between",
              borderBottom: "2px solid " + theme.clear_blue,
              paddingBottom: "6px",
              marginBottom: '10px'
            }}
          >
            <CustomLabel>Öppettider</CustomLabel>
            <Button
          primary
          type="primary"
          size="large"
          onClick={() => {
            setAddNewPeriodSchedule(!addNewPeriodSchedule);
          }}
        >
          + Ny öppettid
        </Button>
          </Row>


      {addNewPeriodSchedule && (
        <div style={{marginBottom: '6px'}}>
        <ScheduleTimeBlock
          schedule={{}}
          startOpen
          editable
          onSave={schedule => {
            saveScheduleToPeriod({ period: season.periods[0], schedule });
          }}
        />
        </div>
      )}

      {season &&
        showTimes &&
        season &&
        season.periods &&
        season.periods.map(period => {
          return period.schedules.map(schedule => {
            return (
              <div style={{marginBottom: '6px'}}>
              <ScheduleTimeBlock
                onDelete={schedule => {
                  deleteScheduleFromPeriod({
                    period: season.periods[0],
                    schedule
                  });
                }}
                startOpen
                schedule={schedule}
                editable
              />
              </div>
            );
          });
        })}
      <div>
        <div
          style={{
            paddingBottom: "10px",
            marginBottom: "10px"
          }}
        >

          <Row
            style={{
              marginTop: 20,
              width: "100%",
              justifyContent: "space-between",
              borderBottom: "2px solid " + theme.clear_blue,
              paddingBottom: "6px"
            }}
          >
            <CustomLabel>Kopplade Regler</CustomLabel>
            <Dropdown overlay={menu}>
              <Button type="primary" size="large">
                Lägg till ny regel <Icon type="down" />
              </Button>
            </Dropdown>
          </Row>
          {season &&
            showRules &&
            season &&
            season.rules &&
            season.rules.map(rule => {
              const rulePeriods = season.rulePeriods
                ? season.rulePeriods.filter(rulePeriod => {
                    return rulePeriod.rule === rule.id;
                  })
                : [];
              return (
                <RuleContainer>
                  <Row
                    style={{
                      marginTop: 6,
                      width: "100%",
                      justifyContent: "space-between"
                    }}
                  >
                    <CustomLabel style={{ opacity: 1 }}>
                      {rule.name}
                    </CustomLabel>
                    <Button
                    type="primary"
                      style={{ alignSelf: "flex-end" }}
                      onClick={() => {
                        removeFromSeason({ rule, season });
                      }}
                    >
                      X
                    </Button>
                  </Row>
                  <Row>
                    <div style={{ marginTop: 10 }}>
                      <div style={{ width: "100%" }}>
                        {rulePeriods.map(rulePeriod => {
                          return rulePeriod.schedules.map(schedule => {
                            return (
                              <ScheduleTimeForm
                                prefix="Gäller"
                                weekOfMonths
                                schedule={schedule}
                                
                                onSave={newSchedule => {
                                  updateRulePeriod({schedule: newSchedule.input, rulePeriod, season});
                                }}
                                /*
                                onDelete={onDelete}
                                */
                              />
                            );
                          });
                        })}
                      </div>
                    </div>
                  </Row>
                </RuleContainer>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SeasonItem;
