import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Button, DatePicker, Icon, theme } from "@golfersweden/pegora-ui";
import { useQuery } from "@apollo/react-hooks";
import { GET_SEASONS } from "graphql/Queries";
import generateConfig from "helpers/generateConfig";
import { GET_STARTTIME_SCHEDULE } from "graphql/Queries";
import ExternalClient from "graphql/ExternalClient";
import NavBar from "../elements/NavBar";
import MonthBlock from "../elements/MonthBlock";
import LoadingContainer from "../elements/LoadingContainer";
import Empty from "../elements/Empty";

import _ from "lodash";
const ResultContainer = styled.div`
  transition: height 3.5s ease-in;
  margin: 0px 0px;
  display: block;
`;

const Container = styled.div`
  padding: 10px;
`;

const CustomDatePicker = styled(DatePicker)`
  font-size: 15px;
  text-align: center;
  flex-basis: 100%;
  align-self: stretch;
`;

/*
const Bar = styled.div`
  width: ${props => (props.width ? props.width + "%" : "100%")};
  display: inline-block;
  background-color: ${props => (props.color ? props.color : "#BBB")};
  height: 10px;
`;

const BarContainer = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  display: block;
  bottom: 0;
  height: 26px;
`;

const isMonthWithin = (date, from, to, monthNumber) => {
  var compareDate = moment(date);

  compareDate.set("month", monthNumber);
    const startDate = moment(from);
    const endDate = moment(to);
  return compareDate.isBetween(startDate, endDate, "month", "[]");
};
*/

const ScheduleYearView = ({
  configuration,
  setDate,
  date,
  profile,
  occupancy
}) => {
  const { data: seasonData } = useQuery(GET_SEASONS);
  console.log(seasonData);

  const startDate = moment(date).startOf("year");
  const endDate = moment(date).endOf("year");

  console.log(startDate.format(), startDate.toISOString());
  console.log(startDate);
  const conf = generateConfig({
    resolution: "year",
    config: configuration,
    date: startDate.format(),
    endDate: endDate.format(),
    occupancy,
    profile
  });

  const { loading, data } = useQuery(GET_STARTTIME_SCHEDULE, {
    variables: { input: conf },
    clientState: { defaults: {}, resolvers: {} },
    client: ExternalClient,
    context: {
      headers: {
        "Accept-Language": configuration && configuration.getLanguage.code
      }
    }
  });

  const onAddDate = () => {
    const newDate = moment(date)
      .add(1, "year")
      .toISOString();
    setDate(newDate);
  };

  const onSubDate = () => {
    const newDate = moment(date)
      .subtract(1, "year")
      .toISOString();
    setDate(newDate);
  };

  const onSetDate = (moment, datestring) => {
    setDate(datestring);
  };

  let bookableItemsByMonth = {};

  let isDollar = false;

  if (data && data.startTimeSchedule) {
    if (data.startTimeSchedule.bookableItems.length) {
      isDollar = data.startTimeSchedule.bookableItems[0].localizedPrice.includes(
        "$"
      );
    }

    bookableItemsByMonth = _.groupBy(
      data.startTimeSchedule.bookableItems,
      bookableItem => {
        return moment(bookableItem.startTime).format("M");
      }
    );

    console.log(bookableItemsByMonth);
  }

  return (
    <Container>
      <NavBar>
        <Button
          style={{ color: theme.clear_blue }}
          type="link"
          icon="left"
          onClick={onSubDate}
        />
        <CustomDatePicker
          onChange={onSetDate}
          value={moment(date)}
          format="YYYY"
          allowClear={false}
          suffixIcon={
            <Icon type="down" style={{ color: "white", fontWeight: "bold" }} />
          }
        />
        <Button
          style={{ color: theme.clear_blue }}
          type="link"
          icon="right"
          onClick={onAddDate}
        />
      </NavBar>

      <ResultContainer>
        <LoadingContainer loading={loading}>
          {Object.keys(bookableItemsByMonth).length ? (
            moment.months().map((month, i) => {
              console.log(month);
              const monthDate = moment(date).set("month", i + 1);
              if (bookableItemsByMonth && bookableItemsByMonth[i + 1]) {
                return (
                  <MonthBlock
                    key={i}
                    isDollar={isDollar}
                    month={month}
                    bookableItems={bookableItemsByMonth[i + 1]}
                    date={monthDate}
                  />
                );
              } else {
                return null;
              }
            })
          ) : (
            <div>
              {!loading && (
                <Empty>Inga tider för {moment(date).format("YYYY")}</Empty>
              )}
            </div>
          )}
        </LoadingContainer>
      </ResultContainer>

      {/* 
      <ResultContainer>
        {moment.months().map((month, i) => {
          let bookableItems = [];
                      
          if (data && data.startTimeSchedule) {
            bookableItems = data.startTimeSchedule.bookableItems.filter(
              booking => {
                // console.log(new Date(booking.startTime).getMonth(), 1);
                return (
                  new Date(booking.startTime).getMonth() === i
                  //moment(booking.startTime).format("M") === i+1
                );
              }
            );
          }

          return (
            <Block>
              <p>{month}</p>
              <OccupancyBar bookableItems={bookableItems} />
              <BarContainer>
                {seasonData &&
                  seasonData.listSeasons &&
                  seasonData.listSeasons.map(season => {
                    return season.timePeriods.map(timePeriod => {
                      const isBetween = isMonthWithin(
                        date,
                        timePeriod.from,
                        timePeriod.to,
                        i
                      );

                      let width = 100;
                      
                      const monthMoment = moment(date).set("month", month);
                      const diffTo = monthMoment
                        .endOf("month")
                        .diff(moment(timePeriod.from), "days");
                      const diffFrom = moment(timePeriod.to).diff(
                        monthMoment.startOf("month"),
                        "days"
                      );

                      if (
                        isBetween &&
                        diffFrom &&
                        diffFrom < monthMoment.daysInMonth()
                      ) {
                        width = (
                          (diffFrom / monthMoment.daysInMonth()) *
                          100
                        ).toString();
                      }

                      if (
                        isBetween &&
                        diffTo &&
                        diffTo < monthMoment.daysInMonth()
                      ) {
                        width = (
                          (diffTo / monthMoment.daysInMonth()) *
                          100
                        ).toString();
                      }

                      if (isBetween) {
                        return <Bar color={season.color} width={width} key={season.id} />;
                      } else {
                        return null;
                      }
                    });
                  })}
              </BarContainer>
            </Block>
          );
        })}
      </ResultContainer>
      */}
    </Container>
  );
};

export default ScheduleYearView;
