import gql from "graphql-tag";

export const CREATE_SCHEDULE = gql`
  mutation($input: ScheduleCreateInput!) {
    createSchedule(input: $input) {
      id
      startHour
      endHour
      startMinute
      endMinute
      daysTag
    }
  }
`;
export const UPDATE_SCHEDULE = gql`
  mutation($input: ScheduleUpdateInput!) {
    updateSchedule(input: $input) {
      id
      startHour
      endHour
      startMinute
      endMinute
      daysTag
    }
  }
`;

export const DELETE_SCHEDULE = gql`
  mutation($input: ScheduleDeleteInput!) {
    deleteSchedule(input: $input) {
      id
    }
  }
`;

export const CREATE_PERIOD = gql`
  mutation($input: PeriodCreateInput!) {
    createPeriod(input: $input) {
      id
      title
    }
  }
`;
export const UPDATE_PERIOD = gql`
  mutation($input: PeriodUpdateInput!) {
    updatePeriod(input: $input) {
      id
      title
      schedules {
        id
        startHour
        endHour
        startMinute
        endMinute
        daysTag
      }
      rules {
        id
        name
        description
        expressions {
          inverted
          ruleType
          conjunction
          operator
          value
        }
      }
    }
  }
`;
export const DELETE_PERIOD = gql`
  mutation($input: PeriodDeleteInput!) {
    deletePeriod(input: $input)
  }
`;
export const UPDATE_BASE_CONFIG = gql`
  mutation($input: UpdateBaseConfigInput!) {
    updateBaseConfig(input: $input) {
      id
      maxEntries
      resolutionInterval
      resolutionType
    }
  }
`;

export const UPDATE_LANGUAGE = gql`
  mutation($input: UpdateLanguageInput!) {
    updateLanguage(input: $input) {
      id
      code
    }
  }
`;

export const UPDATE_PRICE_CONFIG = gql`
  mutation($input: UpdatePriceConfigInput!) {
    updatePriceConfig(input: $input) {
      id
      basePrice
      dynamic
      limitIndex
      percentHigh
      percentLow
    }
  }
`;

export const CREATE_SEASON = gql`
  mutation($input: SeasonCreateInput!) {
    createSeason(input: $input) {
      id
      title
      color
      rules {
        id
        name
        description
        expressions {
          inverted
          ruleType
          conjunction
          operator
          value
        }
      }
      periods {
        id
        title
        schedules {
          id
          startHour
          endHour
          startMinute
          endMinute
          daysTag
        }
      }
      timePeriods {
        from
        to
      }
    }
  }
`;
export const UPDATE_SEASON = gql`
  mutation($input: SeasonUpdateInput!) {
    updateSeason(input: $input) {
      id
      title
      color
      rules {
        id
        name
        description
        expressions {
          inverted
          ruleType
          conjunction
          operator
          value
        }
      }
      rulePeriods {
        id
        rule
        schedules {
          id
          startHour
          endHour
          startMinute
          endMinute
          daysTag
          weekOfMonths
        }
      }
      periods {
        id
        title
        schedules {
          id
          startHour
          endHour
          startMinute
          endMinute
          daysTag
        }
      }
      timePeriods {
        from
        to
      }
    }
  }
`;
export const DELETE_SEASON = gql`
  mutation($input: SeasonDeleteInput!) {
    deleteSeason(input: $input)
  }
`;

export const CREATE_EVENT = gql`
  mutation($input: EventCreateInput!) {
    createEvent(input: $input) {
      id
      title
      color
      rules {
        id
        name
        description
        expressions {
          inverted
          ruleType
          conjunction
          operator
          value
        }
      }
      periods {
        id
        title
        schedules {
          id
          startHour
          endHour
          startMinute
          endMinute
          daysTag
        }
      }
      timePeriods {
        from
        to
      }
    }
  }
`;
export const UPDATE_EVENT = gql`
  mutation($input: EventUpdateInput!) {
    updateEvent(input: $input) {
      id
      title
      color
      rules {
        id
        name
        description
        expressions {
          inverted
          ruleType
          conjunction
          operator
          value
        }
      }
      periods {
        id
        title
        schedules {
          id
          startHour
          endHour
          startMinute
          endMinute
          daysTag
        }
      }
      timePeriods {
        from
        to
      }
    }
  }
`;
export const DELETE_EVENT = gql`
  mutation($input: EventDeleteInput!) {
    deleteEvent(input: $input)
  }
`;

export const CREATE_RULE = gql`
  mutation($input: RuleCreateInput!) {
    createRule(input: $input) {
      id
      name
      description
      expressions
    }
  }
`;
export const UPDATE_RULE = gql`
  mutation($input: RuleUpdateInput!) {
    updateRule(input: $input) {
      id
      name
      description
      expressions
    }
  }
`;

export const LOAD_CONFIG = gql`
  mutation($input: LoadConfigInput!) {
    loadConfig(input: $input)
  }
`;
