import React, { useState } from "react";
import "@golfersweden/pegora-ui/dist/bundle.css";
import {
  // GET_STARTTIME_SCHEDULE,
  GET_ALL,
  GET_BASE_CONFIG
  /*
  GET_RULES,
  GET_PERIODS,
  GET_SEASONS
  */
} from "graphql/Queries";
import PageHeader from "elements/PageHeader";
import Page from "elements/Page";
import PageContent from "elements/PageContent";
import Calendar from "components/Calendar/Calendar";
import { Menu, Dropdown, Icon, theme } from "@golfersweden/pegora-ui";
import styled from "styled-components";
/** MULTIPLE QUERIES */
// https://stackoverflow.com/questions/43380704/react-apollo-make-multiple-queries

import { useQuery, useMutation } from "@apollo/react-hooks";

import { LOAD_CONFIG } from "graphql/Mutations";
import configs from "data/configs";
const DropdownHeader = styled.h4`
  color: ${theme.pegora_navy};
  cursor: pointer;
  white-space: nowrap;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
`;

const PageContainer = styled.div`
  text-align: center;
`;

const menuItems = Object.keys(configs).map(key => {
  return {
    key,
    name: configs[key].baseConfig.title
  };
});
const OverviewPage = () => {
  const { loading, error, data } = useQuery(GET_ALL);
  console.log("LOADING", loading);
  const [selectedItem, setSelectedItem] = useState(null);

  const [loadConfig] = useMutation(LOAD_CONFIG, {
    refetchQueries: [{ query: GET_ALL }, { query: GET_BASE_CONFIG }]
  });

  const menu = (
    <Menu
      onClick={e => {
        const config = configs[parseInt(e.key)];

        console.log("the config: ", config);

        loadConfig({
          variables: { input: { config: JSON.stringify(config) } }
        });
        setSelectedItem(config.baseConfig.title);
      }}
    >
      {menuItems.map(menuItem => (
        <Menu.Item key={menuItem.key}>
          <Icon type="user" />
          {menuItem.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Page>
      <PageContainer>
        <div style={{display: 'inline-block', textAlign: 'left', width: '100%',maxWidth: 1200}}>
        <PageHeader
          title="Golfer Sweden FlexSheet™"
          actionButtons={[
            <Dropdown overlay={menu} key="1">
              <DropdownHeader>
                {selectedItem ? selectedItem : "Välj klubb"}{" "}
                <Icon type="down" />
              </DropdownHeader>
            </Dropdown>
          ]}
        />
        <PageContent>
          <Calendar configuration={data} loading={loading} error={error} />
        </PageContent>
        </div>
      </PageContainer>
    </Page>
  );
};

export default OverviewPage;
