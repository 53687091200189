import React from "react";
import styled from "styled-components";

import { GET_ALL } from "graphql/Queries";
import week_days from "data/week_days";
import { useQuery } from "@apollo/react-hooks";

import {
  getActiveRulesForDay,
  getSchedulesInTimePeriod
} from "helpers/objectParsers";
import moment from "moment";

// import week_days from "data/week_days";
// import SchedulePeriod from "components/ScheduleEditor/PeriodItem";
// import { GET_SEASONS } from "graphql/Queries";
import OccupancyBar from "./OccupancyBar";
import { theme } from "@golfersweden/pegora-ui";


const isDayWithin = (date, from, to, dayNumber) => {
  var compareDate = moment(date).startOf("month");
  compareDate.set("date", dayNumber);
  var startDate = moment(from, "YYYY-MM-DD");
  var endDate = moment(to, "YYYY-MM-DD");

  return compareDate.isBetween(startDate, endDate, "hour", "[]");
};



const Header = styled.p`
  font-size: 16px;
`;

const TimeLabel = styled.p`
  font-size: 16px;
  color: ${theme.pegora_navy};
`;
const Pill = styled.p`
  padding: 6px 12px;
  display: inline-block;
  text-align: left;
  border-radius: 16px;
  font-size: 12px;
  color: white;
  clear: both;
  background-color: ${theme.pegora_navy};
`;

const PriceLabel = styled.p`
  font-family: Space Grotesk;
  font-size: 26px;
  color: ${theme.pegora_navy};
`;

/*
const InformationBoxContent = ({ data, date, day }) => {
  let periods = [];

  const dayOfWeek = moment(date).day();

  
  data && data.seasons.forEach(season => {
    season.timePeriods.forEach(timePeriod => {
      if (isDayWithin(date, timePeriod.from, timePeriod.to, day)) {
        let newPeriods = JSON.parse(JSON.stringify(season.periods));
        newPeriods = newPeriods.map(newPeriod => {
          newPeriod.schedules = newPeriod.schedules.filter(
            time => {
              return week_days[time.daysTag].dayArray.includes(dayOfWeek);
            }
          );
          return newPeriod;
        });
        periods = [...periods, ...newPeriods];
      }
    });
  });


  return (
    <div>
      {data && data.seasons.map(season => {
        return season.timePeriods.map(timePeriod => {
          const isBetween = isDayWithin(
            date,
            timePeriod.from,
            timePeriod.to,
            day
          );
          if (isBetween) {
            return (
              <Bar color={season.color} key={day}>
                {season.title}
              </Bar>
            );
          } else {
          }
        });
      })}
      <p>Tidsregler som påverkar dagen:</p>
      {periods.length ? periods.map(period => {
        return <SchedulePeriod period={period} />;
      }) : (<p>Perioden har inga öppettider idag</p>)}
    </div>
  );
};

*/

const DayBlockPopup = ({
  averagePrice,
  date,
  dayString,
  day,
  bookableItems,
  isDollar
}) => {
  const { data } = useQuery(GET_ALL);

  /*
  console.log(date);

  const startTime = moment(bookableItems[0].startTime).startOf("day");

  console.log(startTime, startTime.format(), startTime.toISOString());
  */

  /*
  console.log("Date in DauBLockPopup: ", date);
  const endTime = moment(date)
    .endOf("day");
    
    */
  const startTime = moment(date)
    .startOf("day");

    const dayOfWeek = moment(date).day();

  const activeRules = getActiveRulesForDay(startTime, data);
  let periods = [];
  const activeSchedules = getSchedulesInTimePeriod(date, null, data);

  if (activeSchedules && activeSchedules.seasons) {
    activeSchedules.seasons.forEach(season => {
      season.timePeriods.forEach(timePeriod => {
        if (
          isDayWithin(date, timePeriod.from, timePeriod.to, dayOfWeek)
        ) {
          let newPeriods = JSON.parse(JSON.stringify(season.periods));
          newPeriods = newPeriods.map(newPeriod => {
            newPeriod.schedules = newPeriod.schedules.filter(time => {
              return week_days[time.daysTag].dayArray.includes(dayOfWeek);
            });
            return newPeriod;
          });
          periods = [...periods, ...newPeriods];
        }
      });
    });
  }
  

  return (
    <div style={{ textAlign: "center" }}>
      <TimeLabel>{dayString}</TimeLabel>
      <div style={{ marginBottom: 12 }}>
        <OccupancyBar bookableItems={bookableItems} />
      </div>
      <div style={{ marginBottom: 10 }}>
          <Header>Aktiva tidsinställningar</Header>
          {activeSchedules.seasons.length > 0 && (
            <div>
              {activeSchedules.seasons.map(season => (
                <div>
                  <div>
                    <Pill color={season.color} key={season.id}>
                      {season.title}
                    </Pill>
                  </div>
                  {/* periods.length ? (
                  periods.map(period => {
                    return <div><Pill>Tidsperiod Implementeras</Pill></div>
                    // return <SchedulePeriod period={period} />;
                  })
                ) : (
                  <p>Perioden har inga öppettider idag</p>
                )*/}
                </div>
              ))}
            </div>
          )}
        </div>
      <div style={{ marginBottom: 12 }}>
        
        <Header>Aktiva regler</Header>
        {activeRules.map(rule => (
          <div>
          <Pill>{rule.name}</Pill>
          </div>
        ))}
      </div>

      {!isDollar ? (
        <PriceLabel>
          {averagePrice} <span style={{ fontSize: 20 }}>kr</span>
        </PriceLabel>
      ) : (
        <PriceLabel>
          <span style={{ fontSize: 20 }}>$</span>
          {averagePrice}
        </PriceLabel>
      )}
    </div>
  );
};

export default DayBlockPopup;
